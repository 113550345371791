import SearchIcon from '@jibin/common/icons/Search/Search';
import {
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Skeleton,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  SwipeableDrawer,
  IconButton,
  OutlinedInput,
  FormControl,
  Checkbox,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  ListItemText
} from '@mui/material';

import { Box } from '@mui/system';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useSearchParams, useLocation } from 'react-router-dom';
import { RemediationApi } from '../Remediation/Remediation.Api';
import { ServiceReportsApi } from './ServiceReports.Api';
import ServiceReportsSideBar from './ServiceReportSideBar/ServiceReportsSideBar';
import ServiceReportRow from './ServiceReportRow';
import { UserRoles } from '@jibin/common/utils/constants/ConstantName';
import { NoDataAvailable, NoSearchDataAvailable } from '../Common/NoDataAvailable';
import { EllipsisContent } from '@jibin/common/components/EllipsisContent';
import FilterCancelIcon from '@jibin/common/icons/FilterCancelIcon';
import TablePagination from '../Common/TablePagination';
import UploadIcon from '@jibin/common/icons/UploadIconMin/UploadIconMin';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalenderIconFilter from '@jibin/common/icons/CalenderIconFilter/CalenderIconFilter';
import moment from 'moment';
import WarningIcon from '@jibin/common/icons/Warning/Warning';
import CloseIcon from '@jibin/common/icons/Close/Close';
import { LoadingButton } from '@mui/lab';
import { LoginContext } from '@jibin/common/Context/LoginContext';

type Anchor = 'top' | 'left' | 'bottom' | 'right';
var debounce = require('lodash.debounce');
const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      fontSize: '14px',
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 180
    }
  }
};
const ServiceReports: FC = () => {
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const roleDetails = useSelector(({ roleDetails }: any) => roleDetails.roleDetails, shallowEqual);

  const [useParams, setUseParam] = useSearchParams();
  const [endpointStaticData, setEndPointStaticData] = useState([]);
  const [rowData, setRowData] = useState({} as any);

  const toggleDrawer = (anchor: Anchor, open: boolean, el: any, create: boolean = false) => {
    setclick(!click);
    if (!open) {
      useParams.delete('reports_uuid');
      setUseParam(useParams);
      setRowData({});
      GetEndPoint();
    } else {
      if (!create) {
        setParams(el.reports_uuid);
      }
    }

    setRowData(el);
    setState({ ...state, [anchor]: open });
  };
  const setParams = (index) => {
    const params = {
      reports_uuid: index
    };
    setUseParam(params);
  };
  const [isSkeleton, setIsSkeleton] = useState(false);
  const [assignees, setAssignees] = useState({} as any);
  const [search, setSearch] = useState({
    keyword: '',
    service_ids: '',
    start_date: null,
    end_date: null
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(null);
  const [pagination, setPagination] = useState({
    page_no: '1',
    page_size: '10'
  });
  const [state, setState] = useState({
    right: false
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [reportsData, setReportsData] = useState<any[]>([]);
  const [initialEndpoint, setInitialEndpoint] = useState(false);
  const [click, setclick] = useState(false);
  const [isRemediation, setIsRemediation] = useState(null);
  const [providerOptions, setProviderOption] = useState([] as any);
  const [providerOptionsForFilter, setProviderOptionForFilter] = useState([] as any);
  const [openDelete, setOpenDelete] = useState(false);
  const [isDeleteReportButtonLoading, setIsDeleteReportButtonLoading] = useState(false);
  const { engineer } = useContext(LoginContext);
  const [filterData, setFilterData] = useState({
    service_ids: ['']
  });
  const location = useLocation();
  const renderValue = (selected: any, options: any) => {
    const selectedLabels = selected.map((value) => {
      const selectedOption = options.find((option) => option.value === value);
      return selectedOption ? selectedOption.key : '';
    });

    return selectedLabels.join(', ');
  };
  const handleClearFuntion = () => {
    let item = { ...filterData };
    setSearch({
      keyword: '',
      service_ids: '',
      start_date: null,
      end_date: null
    });
    item = { ...item, service_ids: [''] };
    setFilterData(item);
  };

  const GetAssignees = useCallback(async () => {
    setIsSkeleton(true);
    try {
      const res = await RemediationApi.GetAssignees(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid
      );
      setIsSkeleton(false);
      setAssignees(res.data);
    } catch (err) {
      setIsSkeleton(false);
      console.log(err);
    }
  }, [commonDetails]);
  useEffect(() => {
    GetAssignees();
  }, []);
  const handleChangeMultiSelect = useCallback(
    debounce(
      (event: any) => {
        let {
          target: { value, name }
        } = event;
        const filterValue = value.filter((el: any) => el != '');

        let string;
        if (filterValue.length == 0) {
          string = '';
          const dataForApi = { ...search };

          dataForApi[name] = string;
          const item = { ...filterData };
          setSearch(dataForApi);

          item[name] = typeof value === 'string' ? filterValue.split(',') : [''];

          setFilterData(item);
        } else {
          string = filterValue.join(',');
          const dataForApi = { ...search };

          dataForApi[name] = string;
          const item = { ...filterData };
          setSearch(dataForApi);

          item[name] = typeof value === 'string' ? filterValue.split(',') : filterValue;

          setFilterData(item);
        }
      },
      100,
      true
    ),
    [search, setSearch, filterData, setFilterData]
  );

  useEffect(() => {
    setPage(0);
  }, [search]);
  const GetEndPoint = useCallback(async () => {
    try {
      setIsSkeleton(true);
      const res = await ServiceReportsApi.GetAllReports(
        commonDetails.data.company.company_uuid,
        new URLSearchParams({
          ...search,
          start_date: search.start_date ? moment(search.start_date).format('YYYY-MM-DD') : '',
          end_date: search.end_date ? moment(search.end_date).format('YYYY-MM-DD') : '',
          ...pagination
        }).toString()
      );
      setTotalCount(res?.data?.pagination?.total_records);
      // if (searchParams.get('reports_uuid')) {
      //   const filterdData = res.data.data.filter(
      //     (el, index) => el.reports_uuid == searchParams.get('reports_uuid')
      //   );

      //   setTimeout(() => {
      //     const element = document.getElementById(filterdData[0]?.reports_uuid);
      //     if (element) {
      //       //element.scrollIntoView({ behavior: 'smooth'});
      //       var headerOffset = 90;
      //       var elementPosition = element.getBoundingClientRect().top;
      //       var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      //       window.scrollTo({
      //         top: offsetPosition,
      //         behavior: 'smooth'
      //       });
      //       element.classList.add('flash');
      //       setTimeout(() => {
      //         element.classList.remove('flash');
      //       }, 1000);
      //     }
      //   }, 1);

      //   toggleDrawer('right', true, filterdData[0]);
      //   //setRowData(filterdData[0]);
      // }

      if (search.keyword) {
      } else {
        setEndPointStaticData(res.data.data);
      }

      setReportsData(res.data.data);
      if (res.data.data.length) {
        setInitialEndpoint(true);
      }
      setIsSkeleton(false);
    } catch (error) {
      setIsSkeleton(false);
    }
  }, [commonDetails, search, location.search, pagination.page_no, pagination.page_size]);

  useEffect(() => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      page_no: String(page + 1),
      page_size: String(rowsPerPage)
    }));
  }, [page, rowsPerPage]);

  const GetFilterOption = useCallback(async () => {
    try {
      const res = await ServiceReportsApi.GetAllServices();
      const ProviderList = res.data.data.map((el) => {
        return {
          key: `${el.name} (${el.code})`,
          value: el.services_uuid
        };
      });
      const ProviderListForFilter = res.data.data.map((el) => {
        return {
          key: `${el.code}`,
          value: el.services_uuid,
          name: el.name,
          tooltip: el.name
        };
      });
      ProviderList.push({
        key: 'All Services',
        value: ''
      });
      ProviderListForFilter.push({
        key: 'All Services',
        value: ''
      });
      setProviderOption(ProviderList);
      setProviderOptionForFilter(ProviderListForFilter);
    } catch (error) {}
  }, [commonDetails, search, location.search]);

  useEffect(() => {
    if (!click) {
      GetEndPoint();
      GetFilterOption();
    }
  }, [GetEndPoint, GetFilterOption]);
  const handleSearch = (e) => {
    setSearch({ ...search, [e.target.name]: e.target.value });
  };

  const handleConfirmDelete = async (el) => {
    try {
      setIsDeleteReportButtonLoading(true);
      const res = await ServiceReportsApi.DeleteReportData(
        el.service.services_uuid,
        commonDetails.data.company.company_uuid,
        el.reports_uuid
      );

      setOpenDelete(false);
      GetEndPoint();
    } catch (error) {
    } finally {
      setIsDeleteReportButtonLoading(false);
    }
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const getFile = async (el: any, reportsData: any) => {
    try {
      const res = await ServiceReportsApi.GetReportFile(
        reportsData.service.services_uuid,
        commonDetails.data.company.company_uuid,
        reportsData.reports_uuid,
        el.file_name
      );

      const urlName = res?.data?.data;
      const a = document.createElement('a');
      a.href = urlName;
      a.target = '_blank';
      //a.download = `downloadfile.${res.headers['content-type'].split('/')[1]}`;
      a.download = `${el.file_name}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.log('ERROR ::', error);
    }
  };
  const getAllFiles = async (downloadData) => {
    try {
      await Promise.all(
        (downloadData?.files || []).map(async (element) => {
          await getFile(element, downloadData);
        })
      );
    } catch (error) {
      console.log('ERROR:', error);
    }
  };

  return (
    <>
    {isSkeleton ? 
    <Box sx={{ px: 4, py: 3 }}>
    <Skeleton sx={{ p: 5 }} />
  </Box> : 
    
    <Box>
      <Box sx={{ px: 4, py: 1, backgroundColor: '#FBFBFF', width: 1 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ py: 2, display: 'flex', gap: 3 }}>
            <TextField
              placeholder="Search"
              hiddenLabel
              size="small"
              onChange={handleSearch}
              name="keyword"
              value={search.keyword}
              sx={{ backgroundColor: '#FFFFFF' }}
              inputProps={{ sx: { fontSize: '14px', color: '#696969' } }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />

            <FormControl sx={{ width: 188 }}>
              <Select
                size="small"
                displayEmpty
                multiple
                name="service_ids"
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                value={filterData.service_ids}
                onChange={handleChangeMultiSelect}
                input={<OutlinedInput />}
                renderValue={(selected) => renderValue(selected, providerOptionsForFilter)}
                MenuProps={MenuProps}
                sx={{
                  py: 0.35,
                  '.MuiSelect-select': {
                    py: '5.5px'
                  },
                  fontSize: '14px',
                  color: '#696969',
                  minWidth: { xxs: 0, md: '188px' },
                  backgroundColor: '#FFFFFF'
                }}
                inputProps={{ 'aria-label': 'Without label' }}>
                <Box
                  sx={{
                    fontSize: '14px',
                    py: 0,
                    px: 2,
                    '.MuiMenuItem-root': {
                      py: '0px'
                    }
                  }}></Box>
                <MenuItem
                  sx={{
                    fontSize: '14px',
                    display: 'none',

                    py: 0,
                    '.MuiMenuItem-root': {
                      py: '0px'
                    }
                  }}
                  value={''}>
                  <EllipsisContent
                    maxTextWidth={188}
                    text={'All Services'}
                    sx={{ fontSize: '14px' }}
                  />
                </MenuItem>
                {providerOptionsForFilter.map((name, index) => (
                  <MenuItem
                    sx={{
                      fontSize: '14px',
                      display: !name.value ? 'none' : 'flex',

                      py: 0,
                      '.MuiMenuItem-root': {
                        py: '0px'
                      }
                    }}
                    key={name.key}
                    value={name.value}>
                    <Checkbox checked={filterData.service_ids.indexOf(name.value) > -1} />
                    {name.tooltip ? (
                      <Tooltip placement="top" arrow title={name.tooltip}>
                        <ListItemText
                          sx={{ '.MuiTypography-root': { fontSize: '14px' } }}
                          primary={name.key}
                        />
                      </Tooltip>
                    ) : (
                      <EllipsisContent
                        maxTextWidth={150}
                        text={name.key}
                        sx={{ fontSize: '14px' }}
                      />
                    )}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box sx={{ display: 'flex', gap: 3 }}>
              <DatePicker
                className="calendar-filter"
                showIcon
                icon={<CalenderIconFilter />}
                // selected={start_date}
                dateFormat="dd MMM yyyy"
                // onChange={(date) => setStartDate(date)}
                selectsStart
                start_date={search.start_date}
                end_date={search.end_date}
                selected={search.start_date}
                onChange={(date) =>
                  handleSearch({
                    target: {
                      value: date,
                      name: 'start_date'
                    }
                  })
                }
                name="start_date"
                placeholderText="Start Date"
                style={{
                  input: {
                    margin: '7px 10px 6px 25px !important'
                  }
                }}
              />
              <DatePicker
                className="calendar-filter"
                showIcon
                icon={<CalenderIconFilter />}
                dateFormat="dd MMM yyyy"
                placeholderText="End Date"
                selected={search.end_date}
                onChange={(date) =>
                  handleSearch({
                    target: {
                      value: date,
                      name: 'end_date'
                    }
                  })
                }
                name="end_date"
                selectsEnd
                start_date={search.start_date}
                end_date={search.end_date}
                minDate={search.start_date}
              />
            </Box>
            <Box>
              <Button
                onClick={handleClearFuntion}
                sx={{ display: 'flex', gap: 0.5, alignItems: 'center', py: 0.5 }}
                variant="text">
                {' '}
                <FilterCancelIcon /> Clear filter
              </Button>
            </Box>
          </Box>

          <Box>
            <Button
              onClick={() => {
                toggleDrawer('right', true, {}, true);
              }}
              sx={{ display: 'flex', gap: 0.5, alignItems: 'center', py: 0.5 }}
              variant="contained">
              {' '}
              <UploadIcon
                sx={{ color: '#fff', fill: 'transparent', path: { stroke: '#fff' } }}
              />{' '}
              Upload Report
            </Button>
          </Box>
        </Box>
        <SwipeableDrawer
          BackdropProps={{ style: { backgroundColor: 'black', opacity: 0.4 } }}
          sx={{
            '.MuiDrawer-paper': {
              boxShadow: 'none'
            }
          }}
          anchor={'right'}
          open={state['right']}
          onClose={() => toggleDrawer('right', false, {})}
          onOpen={() => toggleDrawer('right', true, rowData)}>
          <ServiceReportsSideBar
            rowData={rowData}
            assignees={assignees}
            isCreateRemediation={isRemediation}
            onClose={() => toggleDrawer('right', false, {})}
            servicesListOption={providerOptions}
            engineer={engineer}
          />
        </SwipeableDrawer>
        {reportsData.length == 0 || !reportsData ? (
          initialEndpoint ? (
            <NoSearchDataAvailable
              title="No Search Results"
              message="Try adjusting your search or filter to find what you are looking for"
            />
          ) : (
            <NoDataAvailable title="No Data Available" message="Upload your first reports" />
          )
        ) : (
          <TableContainer component={Paper} sx={{ border: 'none', boxShadow: 'none' }}>
            {isSkeleton ? (
              <Box sx={{ px: 4, py: 3 }}>
                <Skeleton sx={{ p: 5 }} />
              </Box>
            ) : (
              <>
                <Table
                  sx={{
                    minWidth: 650,
                    borderCollapse: 'separate',
                    backgroundColor: '#FBFBFF',
                    boxShadow: 'none',
                    borderSpacing: '0px 16px',
                    border: 'none'
                  }}
                  aria-label="site list table">
                  <TableHead>
                    <TableRow sx={{ '.MuiTableCell-root': { border: 'none' } }}>
                      <TableCell>
                        <Typography variant="body2">Report Name</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">Service</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">Start Date</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">End Date</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">Uploaded By</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2"> </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reportsData.map((el: any, index: any) => (
                      <ServiceReportRow
                        providers={providerOptions}
                        onClick={(e) => {
                          setIsRemediation(e);
                          toggleDrawer('right', true, el);
                        }}
                        deleteReport={(e) => {
                          setIsRemediation(e);
                          setOpenDelete(true);
                          // deleteReport(el);
                        }}
                        getAllFiles={getAllFiles}
                        engineer={engineer}
                        assignees={assignees}
                        el={el}
                      />
                    ))}
                  </TableBody>
                </Table>
                <TablePagination
                  totalCount={totalCount}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                />
                <Dialog
                  fullWidth
                  maxWidth="sm"
                  open={openDelete}
                  onClose={handleCloseDelete}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description">
                  <DialogTitle
                    id="alert-dialog-title"
                    sx={{
                      px: { xs: 1, md: 2 },
                      py: { xs: 0.5, md: 1 },
                      bgcolor: 'error.red100',
                      mb: { xs: 2, md: 3 }
                    }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}>
                      <Typography
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          color: 'error.main',
                          fontWeight: '600',
                          maxWidth: 'calc(100% - 40px)',
                          p: 1
                        }}>
                        <WarningIcon sx={{ mr: 1 }} />
                        Confirm Deletion
                      </Typography>
                      <IconButton
                        sx={{ color: 'text.primary' }}
                        aria-label="close"
                        onClick={handleCloseDelete}>
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </DialogTitle>
                  <DialogContent sx={{ p: { xs: 2, md: 3 } }}>
                    <DialogContentText
                      id="alert-dialog-description"
                      sx={{ color: 'text.primary', fontWeight: 700, mb: 1 }}>
                      Are you sure do you want to delete this report?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions sx={{ px: { xs: 2, md: 3 }, pb: { xs: 1, md: 2 }, pt: 0 }}>
                    <Button variant="outlined" sx={{ minWidth: 100 }} onClick={handleCloseDelete}>
                      Cancel
                    </Button>
                    <LoadingButton
                      loading={isDeleteReportButtonLoading}
                      variant="contained"
                      sx={{ minWidth: 100 }}
                      color="error"
                      onClick={() => handleConfirmDelete(isRemediation)}>
                      {' '}
                      Confirm
                    </LoadingButton>
                  </DialogActions>
                </Dialog>
              </>
            )}
          </TableContainer>
        )}
      </Box>
    </Box>
}
    </>
  );
};

export default ServiceReports;
