import React, { FC } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import { NotFound } from './Pages/NotFound';
import { Login } from './Pages/Login';
import { useFlags, withLDProvider } from 'launchdarkly-react-client-sdk';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import PrivateRoutes from '@jibin/common/utils/RouteAccessor/PrivateRoutes';
import PublicRoutes from '@jibin/common/utils/RouteAccessor/PublicRoutes';
import ServicesRoutes from '@jibin/common/utils/RouteAccessor/ServicesRoutes';
import Onboarding from './Pages/Onboarding';
import { Signup } from './Pages/Signup';
import { Welcome } from './Pages/Welcome';
import { ForgotPassword } from './Pages/ForgotPassword';
import CompanySettings, {
  CustomerSuccessTemplate,
  FrameWorksTemplate,
  TechnicalInformation
} from '@jibin/common/templates';
import { AuthNonLayoutContainer } from '@jibin/common/components/AuthContainer';
import CompanyProfile from './Pages/CompanySettings/CompanyProfile';
import Applications from './Pages/TechinicalInformation/Applications';
import ExternalInfrastructureAssessment from './Pages/TechinicalInformation/ExternalInfrastructureAssessment';
import WirelessAssessment from './Pages/TechinicalInformation/WirelessAssessment';
import ManageUsers from './Pages/CompanySettings/ManageUsers';
import Integration from './Pages/CompanySettings/Integration';
import Meetings from './Pages/CompanySettings/Meetings';
import Authorizations from './Pages/CompanySettings/Authorizations';
import Licenses from './Pages/CompanySettings/Licenses';
import PlanAndBilling from './Pages/CompanySettings/PlanAndBilling';
import PoliciesAndProcedures from './Pages/PoliciesAndProcedures';
import PoliciesAndProceduresTemplate from './Pages/PoliciesAndProcedures/Template';
import { SecurityAwarness } from './Pages/SecurityAwarnes';
import { Mfa } from './Pages/MFA';
import { Vulnerability } from './Pages/Vulranbility';
import { Remediation } from './Pages/Remediation';
import { Panetration } from './Pages/Panetration';
import GapAnalysis from './Pages/GapAnalysis';

import GapAnalysisNew from './Pages/GapAnalysisNew/Questionnaire';
import { DashBoard } from './Pages/DashBoard';

import ServicesReview from './Pages/CustomerSuccess/ServicesReview';
import EngineeringConsulting from './Pages/CustomerSuccess/EngineeringConsulting';
import { SignupCreate } from './Pages/SignupCreate';
import MyAccount from './Pages/MyAccount';
import EndpointProtection from './Pages/EndpointProtection/EndpointProtection';
import EmailProtection from './Pages/EmailProtection/EmailProtection';
import UpGuard from './Pages/UpGuard';
import CompanyManagement from './Pages/CompanyManagement';
import ComapnyManagementTemplate from './Pages/CompanyManagement/CompanyManagementTemplate';
import CompanyManagementAccount from './Pages/CompanyManagement/CompanyManagementTemplate/CompanyManagementAccount';
import NewCompanyManagementAccount from './Pages/CompanyManagement/CompanyManagementTemplate/CompanyManagementAccount/NewAccountManagement';
import CompanyManagementContacts from './Pages/CompanyManagement/CompanyManagementTemplate/CompanyManagementContacts';
import MaintenanceWithFlag from './MaintenanceFlag';
import { NewDashBoard } from './Pages/DashBoard/newDashBoard';
import { NetwrokDetection } from './Pages/NetwrokDetection/NetwrokDetection';
import CompanyManagementIntegrations from './Pages/CompanyManagement/CompanyManagementTemplate/CompanyManagementIntegrations';
import { SIEM } from './Pages/SIEM/SIEM';
import SecurityAwarnessManage from './Pages/SecurityAwarnes/SecurityAwarnessManage';
import CompanyInformation from './Pages/CompanySettings/CompanyInformation';
import GapAnalysisTemplate from './Pages/GapAnalysisNew';
import OverViewGap from './Pages/GapAnalysisNew/OverViewGap';
import { PortalActivity } from './Pages/PortalActivity';
import RouteChangeTracker from './Pages/Common/GoogleAnalytics';

import { MfaMethod } from './Pages/MfaMethod';
import { MfaSMS } from './Pages/MfaSMS';
import { MfaAuthenticator } from './Pages/MfaAuthenticator';
import ServicesReports from './Pages/Report/ServiceReports';
import CompanyDashboard from './Pages/CompanyDashboard';
import { RiskManagement } from './Pages/RiskManagement';
import { AssetManagement } from './Pages/AssetManagement';
import AuditLogs from './Pages/CompanySettings/AuditLogs/AuditLogs';

const App: FC = () => {
  const { emailProtection } = useFlags();

  return (
    <>
      <MaintenanceWithFlag />

      <Router>
        <RouteChangeTracker />
        <Routes>
          {/* Public Routes */}
          <Route
            path={PageRouteConstants.Login}
            element={
              <PublicRoutes>
                <Login />
              </PublicRoutes>
            }
          />
          <Route
            path={PageRouteConstants.Home}
            element={
              <PublicRoutes>
                <Login />
              </PublicRoutes>
            }
          />
          <Route
            path={PageRouteConstants.MfaMethod}
            element={
              <PublicRoutes>
                <MfaMethod />
              </PublicRoutes>
            }
          />
          <Route
            path={PageRouteConstants.MfaSMS}
            element={
              <PublicRoutes>
                <MfaSMS />
              </PublicRoutes>
            }
          />
          <Route
            path={PageRouteConstants.MfaAuthenticator}
            element={
              <PublicRoutes>
                <MfaAuthenticator />
              </PublicRoutes>
            }
          />
          <Route
            path={PageRouteConstants.Mfa}
            element={
              <PublicRoutes>
                <Mfa />
              </PublicRoutes>
            }
          />
          <Route
            path={PageRouteConstants.Signup}
            element={
              <PublicRoutes>
                <Signup />
              </PublicRoutes>
            }
          />
          <Route
            path={PageRouteConstants.SignupCreate}
            element={
              <PublicRoutes>
                <SignupCreate />
              </PublicRoutes>
            }
          />
          <Route
            path={PageRouteConstants.ForgotPassword}
            element={
              <PublicRoutes>
                <ForgotPassword />
              </PublicRoutes>
            }
          />

          {/* Private Routes */}
          <Route
            path={PageRouteConstants.OnBoarding}
            element={
              <PrivateRoutes>
                <Onboarding />
              </PrivateRoutes>
            }></Route>
          <Route
            path={PageRouteConstants.Any}
            element={
              <PrivateRoutes>
                <NotFound />
              </PrivateRoutes>
            }
          />

          <Route
            path={PageRouteConstants.MyAccount}
            element={
              <PrivateRoutes>
                <MyAccount />
              </PrivateRoutes>
            }></Route>

          <Route
            path={PageRouteConstants.CompanyDashBoard}
            element={
              <PrivateRoutes>
                <CompanyDashboard />
              </PrivateRoutes>
            }></Route>

          <Route
            path={PageRouteConstants.CompanyManagement}
            element={
              <PrivateRoutes>
                <CompanyManagement />
              </PrivateRoutes>
            }></Route>

          <Route
            path={PageRouteConstants.CompanyManagementAccount}
            element={
              <PrivateRoutes>
                <ComapnyManagementTemplate>
                  <NewCompanyManagementAccount />
                </ComapnyManagementTemplate>
              </PrivateRoutes>
            }></Route>

          <Route
            path={PageRouteConstants.CompanyManagementContact}
            element={
              <PrivateRoutes>
                <ComapnyManagementTemplate>
                  <CompanyManagementContacts />
                </ComapnyManagementTemplate>
              </PrivateRoutes>
            }></Route>

          <Route
            path={PageRouteConstants.CompanyManagementIntegrations}
            element={
              <PrivateRoutes>
                <ComapnyManagementTemplate>
                  <CompanyManagementIntegrations />
                </ComapnyManagementTemplate>
              </PrivateRoutes>
            }></Route>
          <Route
            path={PageRouteConstants.PortalActivity}
            element={
              <PrivateRoutes>
                <PortalActivity />
              </PrivateRoutes>
            }></Route>

          {/* Commenting as this is descoped
        <Route path={PageRouteConstants.Notification} element={<PrivateRoutes><NotificationCenter /></PrivateRoutes>}></Route> */}

          <Route
            path={PageRouteConstants.CompanyProfile}
            element={
              <PrivateRoutes>
                <CompanySettings>
                  <CompanyProfile />
                </CompanySettings>
              </PrivateRoutes>
            }></Route>
          <Route
            path={PageRouteConstants.ManageUsers}
            element={
              <PrivateRoutes>
                <CompanySettings>
                  <ManageUsers />
                </CompanySettings>
              </PrivateRoutes>
            }></Route>
          <Route
            path={PageRouteConstants.Integration}
            element={
              <PrivateRoutes>
                <CompanySettings>
                  <Integration />
                </CompanySettings>
              </PrivateRoutes>
            }></Route>
          <Route
            path={PageRouteConstants.General}
            element={
              <PrivateRoutes>
                <CompanySettings>
                  <CompanyInformation />
                </CompanySettings>
              </PrivateRoutes>
            }></Route>

          <Route
            path={PageRouteConstants.Authorizations}
            element={
              <PrivateRoutes>
                <CompanySettings>
                  <Authorizations />
                </CompanySettings>
              </PrivateRoutes>
            }></Route>
          <Route
            path={PageRouteConstants.Licenses}
            element={
              <PrivateRoutes>
                <CompanySettings>
                  <Licenses />
                </CompanySettings>
              </PrivateRoutes>
            }></Route>
          <Route
            path={PageRouteConstants.PlanAndBilling}
            element={
              <PrivateRoutes>
                <CompanySettings>
                  <PlanAndBilling />
                </CompanySettings>
              </PrivateRoutes>
            }></Route>
          {/* uncomment here to enable audit log route */}
          {/* <Route
            path={PageRouteConstants.AuditLogs}
            element={
              <PrivateRoutes>
                <CompanySettings>
                  <AuditLogs />
                </CompanySettings>
              </PrivateRoutes>
            }></Route> */}

          <Route
            path={PageRouteConstants.EndpointProtection}
            element={
              <PrivateRoutes>
                <EndpointProtection />
              </PrivateRoutes>
            }></Route>

          <Route
            path={PageRouteConstants.Applications}
            element={
              <PrivateRoutes>
                <TechnicalInformation>
                  <Applications />
                </TechnicalInformation>
              </PrivateRoutes>
            }
          />
          <Route
            path={PageRouteConstants.ExternalInfrastructureAssessment}
            element={
              <PrivateRoutes>
                <TechnicalInformation>
                  <ExternalInfrastructureAssessment />
                </TechnicalInformation>
              </PrivateRoutes>
            }
          />
          <Route
            path={PageRouteConstants.WirelessAssessment}
            element={
              <PrivateRoutes>
                <TechnicalInformation>
                  <WirelessAssessment />
                </TechnicalInformation>
              </PrivateRoutes>
            }
          />

          <Route
            path={PageRouteConstants.PoliciesAndProcedures}
            element={
              <PrivateRoutes>
                <ServicesRoutes>
                  <PoliciesAndProcedures />
                </ServicesRoutes>
              </PrivateRoutes>
            }
          />
          <Route
            path={PageRouteConstants.PoliciesAndProceduresTemplate + ':id'}
            element={
              <PrivateRoutes>
                <PoliciesAndProceduresTemplate />
              </PrivateRoutes>
            }
          />

          <Route
            path={PageRouteConstants.Vulnerability}
            element={
              <PrivateRoutes>
                <ServicesRoutes>
                  <Vulnerability />
                </ServicesRoutes>
              </PrivateRoutes>
            }></Route>
          <Route
            path={PageRouteConstants.Remediation}
            element={
              <PrivateRoutes>
                <ServicesRoutes>
                  <Remediation />
                </ServicesRoutes>
              </PrivateRoutes>
            }></Route>

          <Route
            path={PageRouteConstants.RiskManagement}
            element={
              <PrivateRoutes>
                <ServicesRoutes>
                  <RiskManagement />
                </ServicesRoutes>
              </PrivateRoutes>
            }></Route>
          <Route
            path={PageRouteConstants.AssetManagement}
            element={
              <PrivateRoutes>
                <ServicesRoutes>
                  <AssetManagement />
                </ServicesRoutes>
              </PrivateRoutes>
            }></Route>
          <Route
            path={PageRouteConstants.Penetration}
            element={
              <PrivateRoutes>
                <ServicesRoutes>
                  <Panetration />
                </ServicesRoutes>
              </PrivateRoutes>
            }></Route>

          {/* <Route path={PageRouteConstants.FrameworksCIS} element={<PrivateRoutes><FrameWorksTemplate><CISTab /></FrameWorksTemplate></PrivateRoutes>}/> */}
          <Route
            path={PageRouteConstants.Frameworks}
            element={
              <PrivateRoutes>
                <FrameWorksTemplate />
              </PrivateRoutes>
            }
          />
          <Route
            path={PageRouteConstants.SecurityAwarenessResult}
            element={
              <PrivateRoutes>
                <ServicesRoutes>
                  <SecurityAwarness />
                </ServicesRoutes>
              </PrivateRoutes>
            }
          />
          <Route
            path={PageRouteConstants.SecurityAwarenessParticupant}
            element={
              <PrivateRoutes>
                <SecurityAwarnessManage />
              </PrivateRoutes>
            }
          />
          <Route
            path={PageRouteConstants.SecurityAwarenessParticupant}
            element={
              <PrivateRoutes>
                <SecurityAwarness />
              </PrivateRoutes>
            }
          />
          <Route
            path={PageRouteConstants.GapAnalysis}
            element={
              <PrivateRoutes>
                <ServicesRoutes>
                  <GapAnalysis />
                </ServicesRoutes>
              </PrivateRoutes>
            }
          />
          <Route
            path={PageRouteConstants.GapAnalysisV2}
            element={
              <PrivateRoutes>
                <ServicesRoutes>
                  <GapAnalysisTemplate>
                    <OverViewGap />
                  </GapAnalysisTemplate>
                </ServicesRoutes>
              </PrivateRoutes>
            }
          />
          <Route
            path={PageRouteConstants.GapAnalysisV2Questionnaire}
            element={
              <PrivateRoutes>
                <ServicesRoutes>
                  <GapAnalysisTemplate>
                    <GapAnalysisNew />
                  </GapAnalysisTemplate>
                </ServicesRoutes>
              </PrivateRoutes>
            }
          />

          <Route
            path={PageRouteConstants.SIEM}
            element={
              <PrivateRoutes>
                <SIEM />
              </PrivateRoutes>
            }
          />

          <Route
            path={PageRouteConstants.DashBoard}
            element={
              <PrivateRoutes>
                <NewDashBoard />
              </PrivateRoutes>
            }
          />

          <Route
            path={PageRouteConstants.UpGuard}
            element={
              <PrivateRoutes>
                <ServicesRoutes>
                  <UpGuard />
                </ServicesRoutes>
              </PrivateRoutes>
            }
          />

          <Route
            path={PageRouteConstants.NetwrokDetection}
            element={
              <PrivateRoutes>
                <ServicesRoutes>
                  <NetwrokDetection />
                </ServicesRoutes>
              </PrivateRoutes>
            }
          />

          {process.env.REACT_APP_SHOW_PARTIAL_FEATURES === 'true' && emailProtection && (
            <Route
              path={PageRouteConstants.EmailProtection}
              element={
                <PrivateRoutes>
                  <ServicesRoutes>
                    <EmailProtection />
                  </ServicesRoutes>
                </PrivateRoutes>
              }
            />
          )}

          <Route
            path={PageRouteConstants.ServicesReview}
            element={
              <PrivateRoutes>
                <CustomerSuccessTemplate>
                  {/* <ServicesReview /> */}
                  <ServicesReports />
                </CustomerSuccessTemplate>
              </PrivateRoutes>
            }
          />
          <Route
            path={PageRouteConstants.EngineeringConsulting}
            element={
              <PrivateRoutes>
                <CustomerSuccessTemplate>
                  <EngineeringConsulting />
                </CustomerSuccessTemplate>
              </PrivateRoutes>
            }
          />
          {/* Non layout comtainer */}
          <Route
            path={PageRouteConstants.Welcome}
            element={
              <AuthNonLayoutContainer>
                <Welcome />
              </AuthNonLayoutContainer>
            }></Route>
        </Routes>
      </Router>
    </>
  );
};

export default withLDProvider({
  clientSideID: '65265546540d0312e88823f9',
  context: {
    kind: 'user',
    key: 'example_user',
    name: 'Example user',
    email: 'User@example.com'
  }
})(App);
