import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Box, Grid, IconButton, Typography, useMediaQuery, Skeleton, Button } from '@mui/material';
import ReactGA from 'react-ga4';

import AlertIcon from '@jibin/common/icons/AlertIcon/AlertIcon';
import EventDashboard from '@jibin/common/icons/EventDashboard/EventDashboard';
import NotableIcon from '@jibin/common/icons/NotableIcon/NotableIcon';
import NewAlert from '@jibin/common/icons/NewAlert/NewAlert';
import EndpointDashboard from '@jibin/common/icons/EndpointDashboard/EndpointDashboard';
import FolderIcon from '@jibin/common/icons/FolderIcon/FolderIocn';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { DashboardApi } from './Dashboard.Api';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { UserRoles } from '@jibin/common/utils/constants/ConstantName';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import moment from 'moment';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import { useLocation, useNavigate } from 'react-router-dom';
import BorderLinearProgress from '@jibin/common/components/LinearLoading/BorderLinearGreen';
import { linearProgressClasses } from '@mui/material/LinearProgress';
import { TopRemediations } from '../Common/TopRemediations/TopRemediations';
import UpGuardChart from '@jibin/common/components/UpGaurdChart';
import { UpGuardApi } from '../UpGuard/UpGuard.Api';
import SimpleBarChart from '../Panetration/BarChart';
import { PanetrationApi } from '../Panetration/Panetration.Api';

import FlagIcon from '@jibin/common/icons/FlagIcon/FlagIcon';
import SettingIconDashboard from '@jibin/common/icons/SettingIconDashboard/SettingIconDashboard';
import SecurityAwarnessBarChart from './SecurityAwarnessBarChart';
import { useFlags } from 'launchdarkly-react-client-sdk';
import './dashboard.css';
import json2mq from 'json2mq';
import Style from '@jibin/common/style/Styles';
import { GapAnalysisApi } from '../GapAnalysisNew/GapAnalysis.Api';
import CheckIcon from '@jibin/common/icons/CheckIcon/CheckIcon';
import { WidthProvider, Responsive } from 'react-grid-layout';
import { saveToLS, getFromLS, formatLayout } from '../Common/DashBoardUtils';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { setLoader } from './../../store/reducers/loaderReducer';
import SettingsIcon from '@mui/icons-material/Settings';
import EditIcon from '@mui/icons-material/Edit';
import DropdownMenu from './DropdownMenu';
import { ColorizeSharp } from '@mui/icons-material';
import FrameWorkPopup from './DashboardFrameWorkPopup/FrameWorkPopup';
import { TopRiskWidget } from '../Common/TopRiskWidget/TopRiskWidget';
import HeadingHeader from '../Common/HeadingHeader';
import GapWidget from '../GapAnalysisNew/GapWidget';
import GapFrameWorkWidget from '../GapAnalysisNew/GapFrameWorkWidget';
import { TopEventWidget } from '../Common/TopEventWidget/TopRiskWidget/TopEventWidget';
import React from 'react';
const ResponsiveGridLayout = WidthProvider(Responsive);
export const NewDashBoard: FC = (props) => {
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const location = useLocation();

  const dispatch = useDispatch();
  const { emailProtection } = useFlags();
  const [pendata, setPenData] = useState({ critical: [], high: [], medium: [] });
  const [securityData, setSecurityData] = useState([]);
  const navigate = useNavigate();

  const [vulnerabilityStats, setVulnerabilityStats] = useState({} as any);

  const [rowData, setRowData] = useState({} as any);
  const [state, setState] = useState({
    right: false
  });

  type Anchor = 'top' | 'left' | 'bottom' | 'right';
  const toggleDrawer = (anchor: Anchor, open: boolean, row: any) => {
    setRowData(row);
    setState({ ...state, [anchor]: open });
  };

  const GetVulnerabilityStats = useCallback(() => {
    DashboardApi.VulnerabilityStats(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid
    ).then(
      (q: any) => {
        setVulnerabilityStats(q.data);
      },
      (error: any) => {
        console.log(error);
      }
    );
  }, [commonDetails]);

  const momentTimezone = require('moment-timezone');

  const [chartData, setChartData] = useState([]);
  const [frameworkChartData, setFrameworkChartData] = useState([]);
  const [trainingData, setTrainingData] = useState({} as any);
  const [emailProtectionStats, setEmailProtectionStats] = useState({} as any);
  const [endpointProtectionStats, setEndpoimyProtectionStats] = useState({} as any);
  const [assetStats, setAssetStats] = useState({} as any);
  const [riskStats, setRiskStats] = useState({} as any);
  const [phishingData, setPhishingData] = useState({} as any);
  const [policystat, setPolicystat] = useState({} as any);
  const [frameworkstat, setFrameworkstat] = useState([] as any);
  const [networkstats, setNetwrokstats] = useState([] as any);
  const [upGuardStats, setUpGuardStats] = useState({} as any);
  const [isDragable, setIsDragable] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentGrid, setCurrentGrid] = useState<any>(null);
  const [widgetSize, setWidgetSize] = useState<number>(null);
  const [changeGridLayout, setChangeGridLayout] = useState([]);
  const [widgetEventDetals, setWidgetEventDetails] = useState(null);
  const ref = useRef([]);

  const loader = useSelector(({ skeleton }: any) => skeleton?.loader, shallowEqual);
  const [serach, setSearch] = useState({
    'start-time': moment(new Date(new Date().setDate(new Date().getDate() - 30)))
      .startOf('day')
      .valueOf()
      .toString(),
    'end-time': moment(new Date()).endOf('day').valueOf().toString(),
    status: ''
  });
  useEffect(() => {
    dispatch(setLoader(true));
  }, []);
  const [data, setData] = useState([]);
  const emailProtectionRef = useRef([]);
  const endpointProtectionRef = useRef([]);
  const assetRef = useRef([]);
  const matches = useMediaQuery(
    json2mq({
      minWidth: 1330
    })
  );
  const GetPhishing = useCallback(() => {
    DashboardApi.phishing(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid
    ).then(
      (q: any) => {
        setPhishingData(q.data);
      },
      (error: any) => {
        console.log(error);
      }
    );
  }, [commonDetails]);
  const GetRemediatedData = useCallback(() => {
    PanetrationApi.PenetrationStatsData(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid
    ).then(
      (q: any) => {
        const critical = [
          { name: 'Open', pv: q.data.data?.critical.open },
          { name: 'Remediated', pv: q.data.data?.critical?.remediated }
        ];
        const high = [
          { name: 'Open', pv: q.data.data?.high.open },
          { name: 'Remediated', pv: q.data.data?.high?.remediated }
        ];
        const medium = [
          { name: 'Open', pv: q.data.data?.medium.open },
          { name: 'Remediated', pv: q.data.data?.medium?.remediated }
        ];
        setPenData({ ...pendata, critical: critical, high: high, medium: medium });
      },
      (error: any) => {
        console.log(error);
      }
    );
  }, [commonDetails]);
  const servicesDetials = useSelector(
    ({ servicesDetials }: any) => servicesDetials?.servicesDetials,
    shallowEqual
  );
  const filterService = (name: any) => {
    const service = servicesDetials?.filter((el) => el.name == name);

    if (service.length > 0) {
      return true;
    } else {
      return false;
    }
  };
  const visibleDefaultLayout = [
    {
      i: 'dashboard-events',
      x: 0,
      y: 0,
      w: 3,
      h: 1,
      isResizable: false,
      visibility: filterService('Events')
    },
    {
      i: 'dashboard-gap-analysis',
      x: 0,
      y: 0,
      w: 1,
      h: 2,
      isResizable: false,
      visibility: filterService('Gap Analysis') && !filterService('Compliance') ? true : false
    },
    {
      i: 'dashboard-gap-analysis-v-2-0',
      x: 0,
      y: 0,
      w: 1,
      h: 2,
      isResizable: false,
      visibility: filterService('Compliance')
    },

    {
      i: 'dashboard-gap-analysis-v-2-0-1',
      x: 1,
      y: 0,
      w: 1,
      h: 2,
      isResizable: false,
      visibility: filterService('Compliance')
    },
    {
      i: 'dashboard-security-awareness',
      x: 1,
      y: 0,
      w: 1,
      h: 2,
      isResizable: false,
      visibility: filterService('Security Awareness')
    },
    {
      i: 'dashboard-security-awareness-1',
      x: 2,
      y: 0,
      w: 1,
      h: 2,
      isResizable: false,
      visibility: filterService('Security Awareness')
    },
    {
      i: 'dashboard-vulnerability-management',
      x: 0,
      y: 8,
      w: 1,
      h: 2,
      isResizable: false,
      visibility: filterService('Vulnerability Management')
    },
    {
      i: 'dashboard-penetration-testing',
      x: 1,
      y: 8,
      w: 1,
      h: 2,
      isResizable: false,
      visibility: filterService('Penetration Testing')
    },
    {
      i: 'dashboard-remediation-tracker',
      x: 2,
      y: 8,
      w: 1,
      h: 2,
      isResizable: false,
      visibility: filterService('Remediation Tracker')
    },
    {
      i: 'dashboard-endpoint-protection',
      x: 0,
      y: 16,
      w: 1,
      h: 2,
      isResizable: false,
      visibility: filterService('Tools')
    },
    {
      i: 'dashboard-asset-management',
      x: 0,
      y: 16,
      w: 1,
      h: 2,
      isResizable: false,
      visibility: filterService('Assets')
    },
    {
      i: 'dashboard-attack-surface-management',
      x: 1,
      y: 16,
      w: 3,
      h: 2,
      isResizable: false,
      visibility: filterService('Attack Surface Management')
    },
    {
      i: 'dashboard-network-protection',
      x: 0,
      y: 24,
      w: 1,
      h: 2,
      isResizable: false,
      visibility: filterService('Network Detection')
    },
    {
      i: 'dashboard-top-risks',
      x: 0,
      y: 24,
      w: 1,
      h: 2,
      isResizable: false,
      visibility: filterService('Risks')
    },
    {
      i: 'dashboard-risks-stats',
      x: 0,
      y: 24,
      w: 1,
      h: 2,
      isResizable: false,
      visibility: filterService('Risks')
    },
    // {
    //   i: 'dashboard-top-events',
    //   x: 0,
    //   y: 24,
    //   w: 1,
    //   h: 2,
    //   isResizable: false,
    //   visibility: filterService('Events')
    // },
    {
      i: 'dashboard-security-info-event-management',
      x: 1,
      y: 32,
      w: 2,
      h: 2,
      isResizable: false,
      visibility: process.env.REACT_APP_SHOW_PARTIAL_FEATURES === 'true' ? true : false
    },
    {
      i: 'dashboard-email-protection',
      x: 0,
      y: 32,
      w: 1,
      h: 2,
      isResizable: false,
      visibility:
        emailProtection &&
        process.env.REACT_APP_SHOW_PARTIAL_FEATURES === 'true' &&
        filterService('Email Protection')
          ? true
          : false
    }
  ];
  const [gridLayout, setGridLayout] = useState(visibleDefaultLayout);
  async function aggregateDataByMonth(dailyData) {
    const currentDate = moment();
    const twelveMonthsAgo = currentDate.subtract(12, 'months');

    const filterData = dailyData
      ?.filter((el) => {
        const objDate = moment(el.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS Z'); // Adjust the format based on your date string

        return objDate.isAfter(twelveMonthsAgo);
      })
      ?.reduce((result, data) => {
        const month = moment(data?.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS Z').format('MMM');
        // const year = moment(data?.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS Z').format('YYYY');
        const cMonth = moment(new Date(), 'YYYY-MM-DD HH:mm:ss.SSSSSS Z').format('MMM');
        // const cYear = moment(new Date(), 'YYYY-MM-DD HH:mm:ss.SSSSSS Z').format('YYYY');
        const key = `${month}`;
        const cKey = `${cMonth}`;

        if (!result[key]) {
          result[key] = { month: key, sum: 0, count: 0 };
        }

        if (cKey === key) {
          result[key].sum = data.score;
          result[key].count = 1;
        } else {
          result[key].sum += data.score;
          result[key].count++;
        }

        return result;
      }, {});
    for (const month in filterData) {
      const average = filterData[month].sum / filterData[month].count;
      filterData[month].value = Math.round(average);
    }
    return filterData;
  }

  const GetUpGuardRatings = useCallback(async () => {
    try {
      const res = await UpGuardApi.UpGuardApiRatings(
        commonDetails.data.user_uuid,

        commonDetails.data.company.company_uuid
      );
      setUpGuardStats(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  }, [commonDetails]);
  const GetUpGuardTrending = useCallback(async () => {
    try {
      const res = await UpGuardApi.UpGuardTrending(
        commonDetails.data.user_uuid,

        commonDetails.data.company.company_uuid
      );

      const monthlyData = Object.values(
        await aggregateDataByMonth(res?.data?.data?.trend?.company_trend)
      );

      setData(monthlyData);
    } catch (error) {
      console.log(error);
    }
  }, [commonDetails]);
  const renderColorBackGround = (severity: any = 'critical') => {
    var color = '#FFE6E2';
    if (severity == 'critical') {
      color = '#FFE6E2';
    } else if (severity == 'high') {
      color = '#FDE68A';
    } else if (severity == 'medium') {
      color = '#FEF3C7';
    } else if (severity == 'low') {
      color = '#D0DAFC';
    }
    return color;
  };
  const renderColor = (severity: any) => {
    var color = '#F87171';
    if (severity == 'critical') {
      color = '#F87171';
    } else if (severity == 'high') {
      color = '#F59E0B';
    } else if (severity == 'medium') {
      color = '#FBBF24';
    } else if (severity == 'low') {
      color = '#436AF3';
    }
    return color;
  };
  const GetTraining = useCallback(() => {
    DashboardApi.training(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid
    ).then(
      (q: any) => {
        setTrainingData(q.data);

        const data = q?.data?.data?.stat?.map((el: any) => {
          return {
            name: el?.name,
            campaign: [
              { name: 'Completed', pv: el?.completed },
              { name: 'InProgress', pv: el?.in_progress },
              { name: 'NotStarted', pv: el?.not_started }
            ]
          };
        });

        let sortedData = data.sort((a, b) => (a.name > b.name ? 1 : -1));

        setSecurityData(sortedData);
        ref.current = [];
        ref.current.push({
          name: 'Completed',
          value: q.data.data.stat.completed,
          fill: 'rgba(16, 185, 129, 1)'
        });
        ref.current.push({
          name: 'In Progress',
          value: q.data.data.stat.in_progress,
          fill: 'rgba(253, 230, 138, 1)'
        });
        ref.current.push({
          name: 'Not Started',
          value: q.data.data.stat.not_started,
          fill: 'rgba(249, 99, 99, 1)'
        });
      },
      (error: any) => {
        console.log(error);
      }
    );
  }, [commonDetails]);

  const GetEmailStats = useCallback(() => {
    DashboardApi.emailProtectionStats(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid
    ).then(
      (q: any) => {
        setEmailProtectionStats(q.data);

        emailProtectionRef.current = [];

        q.data.data.map((c) => {
          let chartArc = { name: c.title, value: c.count, fill: '' };
          switch (c.type) {
            case 'SPAM':
              chartArc.fill = 'rgba(253, 230, 138, 1)';
              break;
            case 'FRAUD':
              chartArc.fill = 'rgba(183, 183, 183, 1)';
              break;
            case 'MALWARE':
              chartArc.fill = 'rgba(245, 158, 11, 1)';
              break;
            case 'CLEAN':
              chartArc.fill = 'rgba(39, 174, 96, 1)';
              break;
            case 'BLOCKED':
              chartArc.fill = 'rgba(248, 113, 113, 1)';
              break;
            default:
              chartArc.fill = 'rgba(39, 174, 96, 1)';
              break;
          }
          emailProtectionRef.current.push(chartArc);
        });
      },
      (err: any) => {}
    );
  }, [commonDetails]);

  const GetEndpointstats = useCallback(() => {
    DashboardApi.endpointProtectionStats(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid
    ).then(
      (q: any) => {
        setEndpoimyProtectionStats(q.data);

        endpointProtectionRef.current = [];

        let othercount = 0;
        q.data.data.map((c) => {
          let skip = false;
          let chartArc = { name: '', value: '', fill: '' };
          switch (c.type) {
            case 'ACTIVE':
              chartArc.name = c.title;
              chartArc.value = c.count;
              chartArc.fill = '#10B981';
              break;
            case 'INACTIVE':
              chartArc.name = c.title;
              chartArc.value = c.count;
              chartArc.fill = '#B7B7B7';
              break;
            case 'QUARANTINE':
              chartArc.name = c.title;
              chartArc.value = c.count;
              chartArc.fill = '#F59E0B';
              break;
            default:
              othercount += c.count;
              skip = true;
              //chartArc.fill = 'rgba(39, 174, 96, 1)';
              break;
          }
          if (!skip) {
            endpointProtectionRef.current.push(chartArc);
          }
        });

        let chartArc = { name: 'Other', value: othercount, fill: 'rgb(253, 230, 138)' };
        endpointProtectionRef.current.sort((a, b) => a.name.localeCompare(b.name));
        endpointProtectionRef.current.push(chartArc);
      },
      (error: any) => {
        console.log(error);
      }
    );
  }, [commonDetails]);
  const GetAssetsStats = useCallback(() => {
    DashboardApi.GetAssetsStats(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid
    ).then(
      (q: any) => {
        console.log(q.data);
        setAssetStats(q.data);

        assetRef.current = [];

        let othercount = 0;
        q.data.data?.status_counts.map((c) => {
          let skip = false;
          let chartArc = { name: '', value: '', fill: '' };
          switch (c.status) {
            case 'Healthy':
              chartArc.name = c.status;
              chartArc.value = c.count;
              chartArc.fill = 'rgba(39, 174, 96, 1)';
              break;
            case 'Alert':
              chartArc.name = c.status;
              chartArc.value = c.count;
              chartArc.fill = 'rgba(252, 211, 77, 1)';
              break;
            case 'Unhealthy':
              chartArc.name = c.status;
              chartArc.value = c.count;
              chartArc.fill = 'rgba(248, 113, 113, 1)';
              break;
            case 'Unknown':
              chartArc.name = c.status;
              chartArc.value = c.count;
              chartArc.fill = '#B7B7B7';
              break;
            default:
              othercount += c.count;
              skip = true;
              //chartArc.fill = 'rgba(39, 174, 96, 1)';
              break;
          }
          if (!skip) {
            assetRef.current.push(chartArc);
          }
        });

        assetRef.current.sort((a, b) => a.name.localeCompare(b.name));
      },
      (error: any) => {
        console.log(error);
      }
    );
  }, [commonDetails]);
  const GetRisksStats = useCallback(() => {
    DashboardApi.GetRisksStats(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid
    ).then(
      (q: any) => {
        setRiskStats(q?.data?.data);
      },
      (error: any) => {
        console.log(error);
      }
    );
  }, [commonDetails]);
  const GetEventDetails = useCallback(() => {
    DashboardApi.GetEventDetails(
      commonDetails.data.company.company_uuid,
      commonDetails.data.user_uuid
    ).then(
      (q: any) => {
        setWidgetEventDetails(q?.data?.data);
        // setRiskStats(q?.data?.data);
      },
      (error: any) => {
        console.log(error);
      }
    );
  }, [commonDetails]);
  const bgColorForEmailProtection = (type: any) => {
    let fill;
    switch (type) {
      case 'SPAM':
        fill = 'rgba(253, 230, 138, 1)';
        break;
      case 'FRAUD':
      case 'OTHERS':
        fill = 'rgb(253, 230, 138)';
        break;
      case 'MALWARE':
        fill = 'rgba(245, 158, 11, 1)';
        break;
      case 'CLEAN':
        fill = 'rgba(39, 174, 96, 1)';
        break;
      case 'BLOCKED':
      case 'QUARENTINED':
        fill = '#F59E0B';
        break;
      case 'INACTIVE':
        fill = '#B7B7B7';
        break;

      default:
        fill = '#10B981';
        break;
    }
    return fill;
  };
  const [currentPage, setCurrentPage] = useState(1);

  const [isFunctionCalled, setIsFunctionCalled] = useState(false);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const ITEMS_PER_PAGE = 2;
  const paginatedData = securityData.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const roundUpValue = (value: any) => {
    return Math.round(value);
  };
  const GetPolicyStat = useCallback(() => {
    DashboardApi.policystats(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid
    ).then(
      (q: any) => {
        setPolicystat(q.data);
      },
      (error: any) => {
        console.log(error);
      }
    );
  }, [commonDetails]);
  const format = (num) => String(num).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,');
  const GetframeworkStats = useCallback(() => {
    DashboardApi.frameworkStats(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid
    ).then(
      (q: any) => {
        if (q?.data) setFrameworkstat(q?.data?.data);
      },

      (error: any) => {
        console.log(error);
      }
    );
  }, [commonDetails]);
  const oldGapCondition = () => {
    const status = filterService('Gap Analysis') && !filterService('Compliance') ? true : false;
    return status;
  };
  const GetNDRData = useCallback(() => {
    DashboardApi.GetNDRDStats(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid,
      new URLSearchParams(serach).toString()
    ).then(
      (q: any) => {
        if (q?.data) setNetwrokstats(q?.data?.data);
      },

      (error: any) => {
        console.log(error);
      }
    );
  }, [commonDetails]);

  const handleLayoutChange = (layout) => {
    setChangeGridLayout(layout);
    setGridLayout(layout);
  };
  const saveLayout = async () => {
    try {
      await DashboardApi.savedCompanyLayout(
        commonDetails?.data?.company?.company_uuid,
        changeGridLayout ? changeGridLayout : visibleDefaultLayout
      );
    } catch (error) {
      console.log(error);
    }
    setIsDragable(false);
    setWidgetSize(null);
  };
  const resetLayout = async () => {
    const res = await DashboardApi.getCompanyLayout(commonDetails?.data?.company?.company_uuid);

    if (res?.data?.data?.dashboard && Array.isArray(res?.data?.data?.dashboard)) {
      setGridLayout(res?.data?.data?.dashboard);
    } else {
      let newLayout = formatLayout(visibleDefaultLayout);
      setGridLayout(newLayout);
    }
    setIsDragable(false);
    setWidgetSize(null);
  };
  const [screenSizeExceeded, setScreenSizeExceeded] = useState(false);

  useEffect(() => {
    function handleResize() {
      if (!screenSizeExceeded && window.innerWidth > 976) {
        setScreenSizeExceeded(true);
        getLayout();
      } else if (screenSizeExceeded && window.innerWidth <= 976) {
        setScreenSizeExceeded(false);
      }
      if (!screenSizeExceeded && window.innerWidth > 748) {
        setScreenSizeExceeded(true);
        getLayout();
      } else if (screenSizeExceeded && window.innerWidth <= 748) {
        setScreenSizeExceeded(false);
      }
      if (!screenSizeExceeded && window.innerWidth > 1196) {
        setScreenSizeExceeded(true);
        getLayout();
      } else if (screenSizeExceeded && window.innerWidth <= 1196) {
        setScreenSizeExceeded(false);
      }
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [screenSizeExceeded]);

  useEffect(() => {
    const callFunctionOnLargeScreen = () => {
      if (window.innerWidth > 1300) {
        getLayout();
      }
    };

    callFunctionOnLargeScreen();

    window.addEventListener('resize', callFunctionOnLargeScreen);

    return () => {
      window.removeEventListener('resize', callFunctionOnLargeScreen);
    };
  }, []);

  const getLayout = async () => {
    try {
      const res = await DashboardApi.getCompanyLayout(commonDetails?.data?.company?.company_uuid);
      if (res?.data?.data?.dashboard && Array.isArray(res?.data?.data?.dashboard)) {
        visibleDefaultLayout.forEach((defaultItem) => {
          const correspondingDBItem = res?.data?.data?.dashboard.find(
            (dbItem) => dbItem.i === defaultItem.i
          );
          if (!correspondingDBItem && defaultItem.visibility) {
            res?.data?.data?.dashboard.push({
              h: defaultItem.h,
              i: defaultItem.i,
              w: defaultItem.w,
              x: defaultItem.x,
              y: defaultItem.y,
              moved: false,
              static: false,
              isResizable: false
            });
          }
        });
        res?.data?.data?.dashboard.forEach((obj) => {
          // obj.h = 2;
          obj.isResizable = false;
        });
        setGridLayout(res?.data?.data?.dashboard);
      } else {
        const newLayout = formatLayout(visibleDefaultLayout);
        setGridLayout(newLayout);
      }
    } catch (error) {
      const newLayout = formatLayout(visibleDefaultLayout);
      setGridLayout(newLayout);
      setLoader(false);
      console.log(error);
    }
  };
  useEffect(() => {
    const isPresentIndex = gridLayout.findIndex((grid) => grid?.i === currentGrid?.i);
    if (isPresentIndex !== -1 && widgetSize !== null) {
      const updatedGridLayout = [...gridLayout];
      updatedGridLayout[isPresentIndex] = {
        ...updatedGridLayout[isPresentIndex],
        w: widgetSize
      };
      setGridLayout(updatedGridLayout);
      setWidgetSize(null);
    }
  }, [widgetSize]);
  useEffect(() => {
    if (filterService('Gap Analysis')) {
      GetframeworkStats();
    }

    if (filterService('Policies')) {
      GetPolicyStat();
    }
    if (filterService('Penetration Testing')) {
      GetRemediatedData();
    }
    if (filterService('Attack Surface Management')) {
      GetUpGuardTrending();
      GetUpGuardRatings();
    }

    if (filterService('Vulnerability Management')) {
      GetVulnerabilityStats();
    }
    if (filterService('Security Awareness')) {
      GetPhishing();
      GetTraining();
    }
    if (filterService('Email Protection')) {
      GetEmailStats();
    }
    if (filterService('Tools')) {
      GetEndpointstats();
    }
    if (filterService('Assets')) {
      GetAssetsStats();
    }
    if (filterService('Risks')) {
      GetRisksStats();
    }
    if (filterService('Network Detection')) {
      GetNDRData();
    }
    if (filterService('Events')) {
      GetEventDetails();
    }

    getLayout();
    setTimeout(function () {
      dispatch(setLoader(false));
    }, 1500);
  }, [
    GetAssetsStats,
    GetRisksStats,
    GetVulnerabilityStats,
    GetPhishing,
    GetTraining,
    GetUpGuardRatings,
    GetPolicyStat,
    GetEndpointstats,
    GetRemediatedData,
    GetframeworkStats,
    GetUpGuardTrending,
    GetNDRData,
    GetEventDetails
    // GetEmailStats
  ]);

  const handleClickNavigate = (e, path) => {
    const element = e?.target?.closest('.remediationSingleBox') || e?.target?.closest('.grid-icon');
    if (element) {
      return;
    }
    if (e.target.classList.contains('redirect-btn')) {
      return;
    }
    if (!isDragable) {
      const element = e?.target?.closest('.react-grid-item');
      if (element) {
        navigate(path);
      }
    }
  };

  const handleClickNavigateButton = (e, path) => {
    if (!isDragable) {
      const element = e?.target?.closest('.react-grid-item');
      if (element) {
        navigate('/risk-management?risk_expire=expire-next-30-days');
      }
    }
  };

  const dragFunc = (e, newItem) => {
    const targetEditElement = e.target.closest('.edit-width-menu');
    if (targetEditElement) {
      return;
    }
    setCurrentGrid(newItem);
    const editIconDropDown = e.target.closest('.grid-edit-icon');
    if (editIconDropDown) {
      setAnchorEl(e.currentTarget);
    }
    const targetElement = e.currentTarget;
    let topPos = 0;
    let leftPos = 0;
    if (targetElement) {
      const rect = targetElement.getBoundingClientRect();
      topPos = rect?.top + 40;
      leftPos = rect?.right - 230;
    }
    setTimeout(() => {
      const closestParent = document.querySelector('.edit-width-menu .MuiMenu-paper');
      if (closestParent) {
        const closestParentWithStyle = closestParent as HTMLElement;
        closestParentWithStyle.style.top = `${topPos}px`;
        closestParentWithStyle.style.left = `${leftPos}px`;
      }
    }, 0);
  };
  const securityInfoManagement = gridLayout?.find(
    (obj) => obj?.i === 'dashboard-security-info-event-management'
  );
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {/* <Box sx={{ ...Style.Common.pageHead, display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            <Typography variant={'subtitle1'} sx={{ mb: 2 }}>
              Dashboard
            </Typography>
            <Typography variant={'subtitle2'}>
              Real-time view of your cybersecurity program
            </Typography>
          </Box>
          <Box sx={{ pt: 2, mr: 3, textAlign: 'right' }}>
            {commonDetails?.data?.group == UserRoles.SUPER_ADMIN && (
              <>
                {isDragable ? (
                  <>
                    <Button variant="outlined" sx={{ mr: 2 }} onClick={resetLayout}>
                      Cancel
                    </Button>
                    <Button variant="contained" onClick={saveLayout}>
                      Save
                    </Button>
                  </>
                ) : (
                  <Button
                    onClick={() => setIsDragable(true)}
                    variant="contained"
                    startIcon={<SettingsIcon />}>
                    Customize
                  </Button>
                )}
              </>
            )}
          </Box>
        </Box> */}
        <HeadingHeader
          component={'dashboard'}
          subtitle1={'Dashboard'}
          subtitle2={'Real-time view of your cybersecurity program'}
          Style={Style}
          commonDetails={commonDetails}
          UserRoles={UserRoles}
          isDragable={isDragable}
          resetLayout={resetLayout}
          saveLayout={saveLayout}
          setIsDragable={setIsDragable}
          SettingsIcon={<SettingsIcon />}
        />
        {loader ? (
          <Box sx={{ px: 4, py: 3 }}>
            <Skeleton sx={{ p: 5 }} />
          </Box>
        ) : (
          <Box sx={{ px: 4, backgroundColor: '#FBFBFF', width: 1 }}>
            <Box sx={{ color: 'rgba(70, 70, 70, 1)', width: 1 }}>
              <Box>
                <ResponsiveGridLayout
                  container
                  spacing={4}
                  sx={{
                    mt: 1
                  }}
                  onLayoutChange={(layout) => {
                    handleLayoutChange(layout);
                  }}
                  className="layout"
                  layouts={{ lg: gridLayout, md: gridLayout, sm: gridLayout }}
                  allowOverlap={false}
                  margin={[24, 24]}
                  breakpoints={{ lg: 1600, md: 1400, sm: 1000, xs: 480 }}
                  cols={{ lg: 3, md: 3, sm: 3, xs: 2 }}
                  rowHeight={191}
                  onDragStart={(layout, oldItem, newItem, placeholder, e, element) => {
                    dragFunc(e, newItem);
                  }}
                  isDraggable={isDragable ? true : false}>
                  {filterService('Events') && (
                    <Grid key="dashboard-events" item>
                      <Box
                        sx={{
                          border: '0',
                          width: 1,
                          height: 1,
                          backgroundColor: 'transparent',
                          borderRadius: '0'
                        }}>
                        <Box sx={{ display: 'flex', gap: 3, height: 1 }}>
                          <Box
                            sx={{
                              backgroundColor: '#ffffff',
                              borderRadius: '8px',
                              border: '1px solid #CCCCCC',
                              width: '33.3%',
                              cursor: 'default'
                            }}
                            onClick={(e) =>
                              handleClickNavigate(
                                e,
                                `${PageRouteConstants?.SIEM}?criticality=Critical`
                              )
                            }>
                            <Box
                              sx={{
                                py: 2,
                                px: 3,
                                display: 'flex',
                                flexDirection: 'column'
                              }}>
                              <Box sx={{ mb: 3 }}>
                                <Typography sx={{ fontWeight: 600, mb: 1 }}>
                                  Critical Events
                                </Typography>
                                <Typography sx={{ fontWeight: 400, fontSize: '12px' }}>
                                  Last 30 days
                                </Typography>
                              </Box>
                              <Box sx={{ display: 'flex', alignItems: 'start' }}>
                                <Box sx={{ width: '33.3%' }}>
                                  <Box
                                    sx={{
                                      width: '64px',
                                      height: '64px',
                                      background: '#FFE6E2',
                                      borderRadius: '50%',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center'
                                    }}>
                                    <FlagIcon sx={{ fontSize: '26px', color: '#F73939' }} />
                                  </Box>
                                </Box>
                                <Box sx={{ width: '33.3%' }}>
                                  <Typography
                                    sx={{
                                      fontSize: '36px',
                                      fontWeight: 500,
                                      color: '#101828',
                                      lineHeight: '30px',
                                      mb: 2
                                    }}>
                                    {widgetEventDetals?.critical?.opened}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    sx={{ fontWeight: 500, color: '#475467' }}>
                                    Opened
                                  </Typography>
                                </Box>
                                <Box sx={{ width: '33.3%' }}>
                                  <Typography
                                    sx={{
                                      fontSize: '36px',
                                      fontWeight: 500,
                                      color: '#101828',
                                      lineHeight: '30px',
                                      mb: 2
                                    }}>
                                    {widgetEventDetals?.critical?.remediated}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    sx={{ fontWeight: 500, color: '#475467' }}>
                                    Remediated
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>

                          <Box
                            sx={{
                              backgroundColor: '#ffffff',
                              borderRadius: '8px',
                              border: '1px solid #CCCCCC',
                              width: '33.3%',
                              cursor: 'default'
                            }}
                            onClick={(e) =>
                              handleClickNavigate(e, `${PageRouteConstants?.SIEM}?criticality=High`)
                            }>
                            <Box
                              sx={{
                                py: 2,
                                px: 3,
                                display: 'flex',
                                flexDirection: 'column'
                              }}>
                              <Box sx={{ mb: 3 }}>
                                <Typography sx={{ fontWeight: 600, mb: 1 }}>High Events</Typography>
                                <Typography sx={{ fontWeight: 400, fontSize: '12px' }}>
                                  Last 30 days
                                </Typography>
                              </Box>
                              <Box sx={{ display: 'flex', alignItems: 'start' }}>
                                <Box sx={{ width: '33.3%' }}>
                                  <Box
                                    sx={{
                                      width: '64px',
                                      height: '64px',
                                      background: '#FDE68A',
                                      borderRadius: '50%',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center'
                                    }}>
                                    <FlagIcon sx={{ fontSize: '26px', color: '#F59E0B' }} />
                                  </Box>
                                </Box>
                                <Box sx={{ width: '33.3%' }}>
                                  <Typography
                                    sx={{
                                      fontSize: '36px',
                                      fontWeight: 500,
                                      color: '#101828',
                                      lineHeight: '30px',
                                      mb: 2
                                    }}>
                                    {widgetEventDetals?.high?.opened}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    sx={{ fontWeight: 500, color: '#475467' }}>
                                    Opened
                                  </Typography>
                                </Box>
                                <Box sx={{ width: '33.3%' }}>
                                  <Typography
                                    sx={{
                                      fontSize: '36px',
                                      fontWeight: 500,
                                      color: '#101828',
                                      lineHeight: '30px',
                                      mb: 2
                                    }}>
                                    {widgetEventDetals?.high?.remediated}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    sx={{ fontWeight: 500, color: '#475467' }}>
                                    Remediated
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>

                          <Box
                            sx={{
                              backgroundColor: '#ffffff',
                              borderRadius: '8px',
                              border: '1px solid #CCCCCC',
                              width: '33.3%',
                              cursor: 'default'
                            }}
                            onClick={(e) =>
                              handleClickNavigate(
                                e,
                                `${PageRouteConstants?.SIEM}?criticality=Medium`
                              )
                            }>
                            <Box
                              sx={{
                                py: 2,
                                px: 3,
                                display: 'flex',
                                flexDirection: 'column'
                              }}>
                              <Box sx={{ mb: 3 }}>
                                <Typography sx={{ fontWeight: 600, mb: 1 }}>
                                  Medium Events
                                </Typography>
                                <Typography sx={{ fontWeight: 400, fontSize: '12px' }}>
                                  Last 30 days
                                </Typography>
                              </Box>
                              <Box sx={{ display: 'flex', alignItems: 'start' }}>
                                <Box sx={{ width: '33.3%' }}>
                                  <Box
                                    sx={{
                                      width: '64px',
                                      height: '64px',
                                      background: '#FEF3C7',
                                      borderRadius: '50%',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center'
                                    }}>
                                    <FlagIcon sx={{ fontSize: '26px', color: '#FBBF24' }} />
                                  </Box>
                                </Box>
                                <Box sx={{ width: '33.3%' }}>
                                  <Typography
                                    sx={{
                                      fontSize: '36px',
                                      fontWeight: 500,
                                      color: '#101828',
                                      lineHeight: '30px',
                                      mb: 2
                                    }}>
                                    {widgetEventDetals?.medium?.opened}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    sx={{ fontWeight: 500, color: '#475467' }}>
                                    Opened
                                  </Typography>
                                </Box>
                                <Box sx={{ width: '33.3%' }}>
                                  <Typography
                                    sx={{
                                      fontSize: '36px',
                                      fontWeight: 500,
                                      color: '#101828',
                                      lineHeight: '30px',
                                      mb: 2
                                    }}>
                                    {widgetEventDetals?.medium?.remediated}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    sx={{ fontWeight: 500, color: '#475467' }}>
                                    Remediated
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  )}

                  {filterService('Gap Analysis') && !filterService('Compliance') && (
                    <Grid
                      key="dashboard-gap-analysis"
                      item
                      onClick={(e) => handleClickNavigate(e, PageRouteConstants?.GapAnalysis)}>
                      <Box
                        sx={{
                          border: '1px solid #CCCCCC',
                          width: 1,
                          height: 1,
                          backgroundColor: '#FFFFFF',
                          borderRadius: '8px',
                          cursor: 'default'
                        }}>
                        <Box
                          sx={{
                            py: 2,
                            px: 3,
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'column',
                            height: '100%'
                          }}>
                          <Box sx={{ mb: 1 }}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                              }}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  gap: 1,
                                  alignItems: 'center'
                                }}>
                                <Typography sx={{ fontWeight: 500, lineHeight: '24px' }}>
                                  Gap Analysis Progress
                                </Typography>
                              </Box>
                              {isDragable && (
                                <>
                                  <EditIcon className="grid-edit-icon" fontSize="small" />
                                  {currentGrid?.i === 'dashboard-gap-analysis' ? (
                                    <DropdownMenu
                                      setAnchorEl={setAnchorEl}
                                      anchorEl={anchorEl}
                                      setWidgetSize={setWidgetSize}
                                    />
                                  ) : null}
                                </>
                              )}
                            </Box>
                          </Box>
                          <Box>
                            <Box
                              sx={{ mb: 2, width: 1, display: 'flex', justifyContent: 'center' }}>
                              <ResponsiveContainer className="container" height={90} width="100%">
                                <Box>
                                  {frameworkstat && frameworkstat?.length == 1
                                    ? frameworkstat?.map((el: any, index: any) => (
                                        <div
                                          className="semi-donut margin"
                                          style={
                                            {
                                              '--percentage': (el.completed / el?.total) * 100,
                                              '--fill':
                                                (el.completed / el?.total) * 100 >= 1 &&
                                                (el.completed / el?.total) * 100 <= 20
                                                  ? '#EF4444'
                                                  : (el.completed / el?.total) * 100 >= 21 &&
                                                    (el.completed / el?.total) * 100 <= 79
                                                  ? '#F59E0B'
                                                  : '#27AE60'
                                            } as any
                                          }>
                                          <Typography
                                            variant="h2"
                                            sx={{
                                              color: '#464646',
                                              fontWeight: 600,
                                              fontSize: '28px'
                                            }}>
                                            {Math.round((el.completed / el?.total) * 100)}%
                                          </Typography>
                                        </div>
                                      ))
                                    : frameworkstat?.map((el: any, index: any) => (
                                        <Box key={index + 1} sx={{ mb: 1 }}>
                                          <Typography variant="body2">{el.name}</Typography>
                                          <Box
                                            sx={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              gap: 1.5
                                            }}>
                                            <Box
                                              sx={{
                                                width: 0.75
                                              }}>
                                              <BorderLinearProgress
                                                variant="determinate"
                                                value={(el.completed / el?.total) * 100}
                                                sx={{
                                                  [`.${linearProgressClasses.bar}`]: {
                                                    borderRadius: 5,
                                                    backgroundColor:
                                                      (el.completed / el?.total) * 100 >= 1 &&
                                                      (el.completed / el?.total) * 100 <= 20
                                                        ? '#EF4444'
                                                        : (el.completed / el?.total) * 100 >= 21 &&
                                                          (el.completed / el?.total) * 100 <= 79
                                                        ? '#F59E0B'
                                                        : '#A7F3D0'
                                                  }
                                                }}
                                              />
                                            </Box>

                                            <Typography variant="caption" sx={{ color: '#696969' }}>
                                              {Math.round((el.completed / el?.total) * 100)}%{' '}
                                              <span> </span>
                                              {`(${el.completed}/${el?.total})`}
                                            </Typography>
                                          </Box>
                                        </Box>
                                      ))}
                                </Box>
                              </ResponsiveContainer>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center', pt: 3 }}>
                              <Typography
                                variant="caption"
                                sx={{ textAlign: 'center', fontWeight: 600 }}>
                                Control Submitted
                              </Typography>
                            </Box>
                          </Box>
                          <Box>
                            {frameworkstat && (
                              <>
                                <Box
                                  sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                                  <Typography variant="caption" sx={{ fontWeight: 600 }}>
                                    Open Controls
                                  </Typography>
                                  <Typography variant="caption" sx={{ fontWeight: 600 }}>
                                    {frameworkstat[0]?.completed}
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                                  <Typography variant="caption" sx={{ fontWeight: 600 }}>
                                    In Progress Controls
                                  </Typography>
                                  <Typography variant="caption" sx={{ fontWeight: 600 }}>
                                    {frameworkstat[0]?.total - frameworkstat[0]?.completed}
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                                  <Typography variant="caption" sx={{ fontWeight: 600 }}>
                                    Submitted Controls
                                  </Typography>
                                  <Typography variant="caption" sx={{ fontWeight: 600 }}>
                                    {frameworkstat[0]?.total}
                                  </Typography>
                                </Box>
                              </>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  )}

                  {filterService('Compliance') && (
                    <Grid
                      key="dashboard-gap-analysis-v-2-0"
                      item
                      onClick={(e) => handleClickNavigate(e, PageRouteConstants?.GapAnalysisV2)}>
                      <GapWidget
                        isDragable={isDragable}
                        currentGrid={currentGrid}
                        setAnchorEl={setAnchorEl}
                        anchorEl={anchorEl}
                        setWidgetSize={setWidgetSize}
                      />
                    </Grid>
                  )}

                  {filterService('Compliance') && (
                    <Grid key="dashboard-gap-analysis-v-2-0-1" item>
                      <GapFrameWorkWidget
                        isDragable={isDragable}
                        currentGrid={currentGrid}
                        setAnchorEl={setAnchorEl}
                        anchorEl={anchorEl}
                        setWidgetSize={setWidgetSize}
                      />
                    </Grid>
                  )}

                  {filterService('Security Awareness') && (
                    <Grid
                      key="dashboard-security-awareness"
                      item
                      onClick={(e) =>
                        handleClickNavigate(e, PageRouteConstants?.SecurityAwarenessResult)
                      }>
                      <Box
                        sx={{
                          border: '1px solid #CCCCCC',
                          width: 1,
                          height: 1,
                          backgroundColor: '#FFFFFF',
                          borderRadius: '8px',
                          cursor: 'default'
                        }}>
                        <Box sx={{ p: 3, display: 'flex', flexDirection: 'column', height: 1 }}>
                          <Box sx={{ mb: 4 }}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                mb: 1
                              }}>
                              <Typography sx={{ fontWeight: 600 }}>
                                Security Awareness Training
                              </Typography>
                              {isDragable && (
                                <>
                                  <EditIcon className="grid-edit-icon" fontSize="small" />
                                  {currentGrid?.i === 'dashboard-security-awareness' ? (
                                    <DropdownMenu
                                      setAnchorEl={setAnchorEl}
                                      anchorEl={anchorEl}
                                      setWidgetSize={setWidgetSize}
                                    />
                                  ) : null}
                                </>
                              )}
                            </Box>
                            {securityData.length > 0 ? (
                              <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                <Typography variant="caption">
                                  {/* {momentTimezone(trainingData.data?.campaign.current.start_date)
                                  .tz('utc')
                                  .format('DD MMM YYYY')
                                  .toUpperCase()}{' '}
                                to{' '}
                                {momentTimezone(trainingData.data?.campaign.current.end_date)
                                  .tz('utc')
                                  .format('DD MMM YYYY')
                                  .toUpperCase()}{' '} */}
                                  Active Campaigns
                                </Typography>
                              </Box>
                            ) : null}
                          </Box>
                          {securityData.length > 0 ? (
                            <Box
                              sx={{
                                display: 'flex',
                                height: 1,
                                flexDirection: 'column',
                                gap: 2,
                                justifyContent: 'space-between'
                              }}>
                              <Box
                                sx={{
                                  position: 'relative',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between'
                                }}>
                                {currentPage === 1 ? (
                                  <></>
                                ) : (
                                  <Box
                                    className="grid-icon"
                                    sx={{ position: 'absolute', left: 0, top: '50%' }}>
                                    <IconButton
                                      sx={{ width: '24px', height: '24px' }}
                                      onClick={() => handlePageChange(currentPage - 1)}
                                      disabled={currentPage === 1}>
                                      <ArrowBackIosNewIcon sx={{ fontSize: '18px' }} />
                                    </IconButton>
                                  </Box>
                                )}

                                <Box sx={{ width: 1, pl: 2.5 }}>
                                  {paginatedData?.map((el: any) => (
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        flexGrow: 1,
                                        my: 1,
                                        justifyContent: 'space-between',
                                        flexDirection: 'column',
                                        borderRadius: '8px',
                                        px: '8px'
                                      }}>
                                      <Typography variant="caption" sx={{ fontWeight: 600 }}>
                                        {el?.name}
                                      </Typography>
                                      <SecurityAwarnessBarChart
                                        data={el?.campaign}
                                        xKey="name"
                                        yKey="pv"
                                        color={'#F59E0B'}
                                      />
                                    </Box>
                                  ))}
                                </Box>
                                {currentPage === Math.ceil(securityData.length / ITEMS_PER_PAGE) ? (
                                  <></>
                                ) : (
                                  <Box
                                    className="grid-icon"
                                    sx={{ position: 'absolute', right: 0, top: '50%' }}>
                                    <IconButton
                                      sx={{ width: '24px', height: '24px' }}
                                      onClick={() => handlePageChange(currentPage + 1)}
                                      disabled={
                                        currentPage ===
                                        Math.ceil(securityData.length / ITEMS_PER_PAGE)
                                      }>
                                      <ArrowForwardIosIcon sx={{ fontSize: '18px' }} />
                                    </IconButton>
                                  </Box>
                                )}
                              </Box>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  width: 1,
                                  // pb: 5,
                                  flexWrap: 'wrap'
                                  // m: 'auto'
                                }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                  <Box
                                    sx={{
                                      width: '14px',
                                      height: '14px',
                                      backgroundColor: 'rgba(16, 185, 129, 1)',
                                      borderRadius: '50%'
                                    }}></Box>
                                  <Typography variant="caption">Completed</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                  <Box
                                    sx={{
                                      width: '14px',
                                      height: '14px',
                                      backgroundColor: 'rgba(253, 230, 138, 1)',
                                      borderRadius: '50%'
                                    }}></Box>
                                  <Typography variant="caption">In Progress</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                  <Box
                                    sx={{
                                      width: '14px',
                                      height: '14px',
                                      backgroundColor: 'rgba(249, 99, 99, 1)',
                                      borderRadius: '50%'
                                    }}></Box>
                                  <Typography variant="caption">Not Started</Typography>
                                </Box>
                                {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Box
                          sx={{
                            width: '14px',
                            height: '14px',
                            backgroundColor: 'rgba(75, 83, 227,1)',
                            borderRadius: '50%'
                          }}></Box>
                        <Typography variant="caption">Passed</Typography>
                      </Box> */}
                              </Box>
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                height: 1,
                                width: 1,
                                textAlign: 'center',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                              }}>
                              <Box
                                sx={{
                                  maxWidth: '196px',
                                  height: '196px',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  width: 1,
                                  borderRadius: '50%',
                                  border: '20px solid #EBEBEB',
                                  p: 2.5
                                }}>
                                <Typography variant="body2">No Active Campaigns</Typography>
                              </Box>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                  )}

                  {filterService('Security Awareness') && (
                    <Grid
                      key="dashboard-security-awareness-1"
                      item
                      onClick={(e) =>
                        handleClickNavigate(e, PageRouteConstants?.SecurityAwarenessParticupant)
                      }>
                      <Box
                        sx={{
                          width: 1,
                          display: 'flex',
                          gap: 3,
                          borderRadius: '8px',
                          border: '1px solid #CCCCCC',
                          backgroundColor: '#FFFFFF',
                          cursor: 'default',
                          height: 1,
                          flexDirection: 'column'
                        }}>
                        <Box
                          sx={{
                            width: 1,
                            flexGrow: 1,
                            display: 'flex',
                            borderRadius: '8px'
                          }}>
                          <Box
                            sx={{
                              p: 3,
                              height: 1,
                              display: 'flex',
                              flexGrow: 1,
                              flexDirection: 'column',
                              justifyContent: 'space-between'
                            }}>
                            <Box>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  mb: 1
                                }}>
                                <Typography sx={{ fontWeight: 600 }}>
                                  Phishing Simulation
                                </Typography>
                                {isDragable && (
                                  <>
                                    <EditIcon className="grid-edit-icon" fontSize="small" />
                                    {currentGrid?.i === 'dashboard-security-awareness-1' ? (
                                      <DropdownMenu
                                        setAnchorEl={setAnchorEl}
                                        anchorEl={anchorEl}
                                        setWidgetSize={setWidgetSize}
                                      />
                                    ) : null}
                                  </>
                                )}
                              </Box>
                              <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                <Typography variant="caption">
                                  {momentTimezone(phishingData.data?.campaign.current.start_date)
                                    .tz('utc')
                                    .format('DD MMM YYYY')
                                    .toUpperCase()}{' '}
                                  to{' '}
                                  {momentTimezone(phishingData.data?.campaign.current.end_date)
                                    .tz('utc')
                                    .format('DD MMM YYYY')
                                    .toUpperCase()}
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                              }}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'flex-start',
                                  gap: 3
                                }}>
                                <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: 0.5 }}>
                                  <Typography
                                    sx={{
                                      fontSize: '40px',
                                      fontWeight: 600,
                                      lineHeight: '32px',
                                      display: 'flex'
                                    }}>
                                    {phishingData.data?.stat.passed
                                      ? phishingData.data?.stat.passed
                                      : 0}
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    sx={{ fontSize: '18px', fontWeight: '600' }}>
                                    of {phishingData.data?.stat?.total_participant}
                                  </Typography>
                                </Box>

                                <Typography
                                  sx={{ fontWeight: 600, color: 'rgba(105, 105, 105, 1)' }}
                                  variant="body2">
                                  <span>
                                    {phishingData.data?.stat.passed
                                      ? Math.round(
                                          Math.round(
                                            ((phishingData.data?.stat.passed /
                                              phishingData.data?.stat.total_participant) *
                                              100 +
                                              Number.EPSILON) *
                                              100
                                          ) / 100
                                        )
                                      : 0}
                                    %{' '}
                                  </span>
                                  of Participants Passed
                                </Typography>
                              </Box>
                              <Box>
                                <CheckIcon />
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                width: 1,
                                pb: 3
                              }}>
                              <Box>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                  }}>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      alignItems: 'flex-start',
                                      gap: 3
                                    }}>
                                    <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: 0.5 }}>
                                      <Typography
                                        sx={{
                                          fontSize: '40px',
                                          fontWeight: 600,
                                          lineHeight: '32px',
                                          display: 'flex'
                                        }}>
                                        {phishingData.data?.stat.failed
                                          ? phishingData.data?.stat.failed
                                          : 0}
                                      </Typography>
                                      <Typography
                                        variant="body1"
                                        sx={{ fontSize: '18px', fontWeight: '600' }}>
                                        of {phishingData.data?.stat?.total_participant}
                                      </Typography>
                                    </Box>

                                    <Typography
                                      sx={{ fontWeight: 600, color: 'rgba(105, 105, 105, 1)' }}
                                      variant="body2">
                                      {' '}
                                      <span>
                                        {phishingData.data?.stat.failed
                                          ? Math.round(
                                              Math.round(
                                                ((phishingData.data?.stat.failed /
                                                  phishingData.data?.stat.total_participant) *
                                                  100 +
                                                  Number.EPSILON) *
                                                  100
                                              ) / 100
                                            )
                                          : 0}
                                        %{' '}
                                      </span>{' '}
                                      of Participants Failed
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <AlertIcon />
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  )}

                  {filterService('Vulnerability Management') && (
                    <Grid
                      key="dashboard-vulnerability-management"
                      item
                      // onClick={(e) => handleClickNavigate(e, PageRouteConstants?.Vulnerability)}
                    >
                      <Box
                        sx={{
                          border: '1px solid #CCCCCC',
                          width: 1,
                          height: 1,
                          backgroundColor: '#FFFFFF',
                          borderRadius: '8px',
                          cursor: 'default'
                        }}>
                        <Box sx={{ p: 3, display: 'flex', flexDirection: 'column', height: 1 }}>
                          <Box sx={{ mb: 5 }}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                mb: 1
                              }}>
                              <Typography sx={{ fontWeight: 600 }}>
                                Vulnerabilities by Criticality
                              </Typography>
                              {isDragable && (
                                <>
                                  <EditIcon className="grid-edit-icon" fontSize="small" />
                                  {currentGrid?.i === 'dashboard-vulnerability-management' ? (
                                    <DropdownMenu
                                      setAnchorEl={setAnchorEl}
                                      anchorEl={anchorEl}
                                      setWidgetSize={setWidgetSize}
                                    />
                                  ) : null}
                                </>
                              )}
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                              <Typography variant="caption">
                                From{' '}
                                {vulnerabilityStats.data?.count?.total
                                  ? vulnerabilityStats.data?.count?.total
                                  : 0}{' '}
                                total findings
                              </Typography>
                            </Box>
                          </Box>
                          <Box sx={{ flexGrow: 1 }}>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1.5,

                                height: 1,
                                justifyContent: 'space-between'
                              }}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  border: '1px solid #EAECF0',
                                  borderRadius: '8px',
                                  p: '14px'
                                }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Box
                                      sx={{
                                        width: '24px',
                                        height: '24px',
                                        backgroundColor: renderColorBackGround('critical'),
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius: '50%'
                                      }}>
                                      <FlagIcon
                                        sx={{
                                          color: renderColor('critical'),
                                          width: '12px'
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                  <Typography
                                    variant={'body1'}
                                    sx={{ fontWeight: 600, color: '#696969' }}>
                                    Critical
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    sx={{ fontWeight: 600, fontSize: '28px', lineHeight: '34px' }}>
                                    {vulnerabilityStats.data?.count?.critical
                                      ? vulnerabilityStats.data?.count?.critical
                                      : 0}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  border: '1px solid #EAECF0',
                                  borderRadius: '8px',
                                  p: '14px'
                                }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                  <Box
                                    sx={{
                                      width: '24px',
                                      height: '24px',
                                      backgroundColor: renderColorBackGround('high'),
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      borderRadius: '50%'
                                    }}>
                                    <FlagIcon
                                      sx={{
                                        color: renderColor('high'),
                                        width: '12px'
                                      }}
                                    />
                                  </Box>
                                  <Typography
                                    variant={'body1'}
                                    sx={{ fontWeight: 600, color: '#696969' }}>
                                    High
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    sx={{ fontWeight: 600, fontSize: '28px', lineHeight: '34px' }}>
                                    {vulnerabilityStats.data?.count?.severe
                                      ? vulnerabilityStats.data?.count?.severe
                                      : 0}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  border: '1px solid #EAECF0',
                                  borderRadius: '8px',
                                  p: '14px'
                                }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                  <Box
                                    sx={{
                                      width: '24px',
                                      height: '24px',
                                      backgroundColor: renderColorBackGround('medium'),
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      borderRadius: '50%'
                                    }}>
                                    <FlagIcon
                                      sx={{
                                        color: renderColor('medium'),
                                        width: '12px'
                                      }}
                                    />
                                  </Box>
                                  <Typography
                                    variant={'body1'}
                                    sx={{ fontWeight: 600, color: '#696969' }}>
                                    Medium
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    sx={{ fontWeight: 600, fontSize: '28px', lineHeight: '34px' }}>
                                    {vulnerabilityStats.data?.count?.moderate
                                      ? vulnerabilityStats.data?.count?.moderate
                                      : 0}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  )}

                  {filterService('Penetration Testing') && (
                    <Grid
                      key="dashboard-penetration-testing"
                      item
                      // onClick={(e) =>
                      //   handleClickNavigate(e, `${PageRouteConstants.Penetration}?source=pen`)
                      // }
                    >
                      <Box
                        sx={{
                          border: '1px solid #CCCCCC',
                          width: 1,
                          height: 1,
                          backgroundColor: '#FFFFFF',
                          borderRadius: '8px',
                          cursor: 'default'
                        }}>
                        <Box sx={{ p: 3, display: 'flex', flexDirection: 'column', height: 1 }}>
                          <Box sx={{ mb: 4 }}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                mb: 1
                              }}>
                              <Typography sx={{ fontWeight: 600 }}>Penetration Testing</Typography>
                              {isDragable && (
                                <>
                                  <EditIcon className="grid-edit-icon" fontSize="small" />
                                  {currentGrid?.i === 'dashboard-penetration-testing' ? (
                                    <DropdownMenu
                                      setAnchorEl={setAnchorEl}
                                      anchorEl={anchorEl}
                                      setWidgetSize={setWidgetSize}
                                    />
                                  ) : null}
                                </>
                              )}
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                              <Typography variant="caption">
                                Open vs. Remediated Status by severity{' '}
                              </Typography>
                            </Box>
                          </Box>

                          <Box
                            sx={{
                              flexGrow: 1,
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-between'
                            }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',

                                  borderRadius: '8px',
                                  px: '14px'
                                }}>
                                <SimpleBarChart
                                  data={pendata.critical}
                                  xKey="name"
                                  yKey="pv"
                                  color={'#F87171'}
                                />
                              </Box>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',

                                  borderRadius: '8px',
                                  px: '14px'
                                }}>
                                <SimpleBarChart
                                  data={pendata.high}
                                  xKey="name"
                                  yKey="pv"
                                  color={'#F59E0B'}
                                />
                              </Box>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',

                                  borderRadius: '8px',
                                  px: '14px'
                                }}>
                                <SimpleBarChart
                                  data={pendata.medium}
                                  xKey="name"
                                  yKey="pv"
                                  color={'#FDE68A'}
                                />
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                display: 'flex',
                                pt: 1.5,
                                justifyContent: 'space-between',

                                width: 1
                              }}>
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Box
                                  sx={{
                                    width: '14px',
                                    height: '14px',
                                    backgroundColor: 'rgba(249, 99, 99, 1)',
                                    borderRadius: '50%'
                                  }}></Box>
                                <Typography variant="caption">Critical</Typography>
                              </Box>
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Box
                                  sx={{
                                    width: '14px',
                                    height: '14px',
                                    backgroundColor: 'rgba(245, 158, 11, 1)',
                                    borderRadius: '50%'
                                  }}></Box>
                                <Typography variant="caption">High</Typography>
                              </Box>
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Box
                                  sx={{
                                    width: '14px',
                                    height: '14px',
                                    backgroundColor: 'rgba(253, 230, 138, 1)',
                                    borderRadius: '50%'
                                  }}></Box>
                                <Typography variant="caption">Medium</Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  )}

                  {filterService('Attack Surface Management') && (
                    <Grid
                      key="dashboard-attack-surface-management"
                      item
                      // onClick={(e) => handleClickNavigate(e, PageRouteConstants.UpGuard)}
                    >
                      <UpGuardChart
                        data={data}
                        upGuardStats={upGuardStats}
                        isDragable={isDragable}
                        currentGrid={currentGrid}
                        customIcon={<EditIcon className="grid-edit-icon" fontSize="small" />}
                        dropdownMenu={
                          <DropdownMenu
                            setAnchorEl={setAnchorEl}
                            anchorEl={anchorEl}
                            setWidgetSize={setWidgetSize}
                          />
                        }
                      />
                    </Grid>
                  )}

                  {filterService('Remediation Tracker') && (
                    <Grid
                      key="dashboard-remediation-tracker"
                      item
                      onClick={(e) =>
                        handleClickNavigate(e, `${PageRouteConstants.Remediation}?source=${''}`)
                      }>
                      <Box
                        sx={{
                          border: '1px solid #CCCCCC',
                          width: 1,
                          height: 1,
                          backgroundColor: '#FFFFFF',
                          borderRadius: '8px',
                          cursor: 'default'
                        }}>
                        <TopRemediations
                          size={10}
                          source=""
                          column={4}
                          title="Top Remediation Tasks"
                          isDragable={isDragable}
                          currentGrid={currentGrid}
                          customIcon={<EditIcon className="grid-edit-icon" fontSize="small" />}
                          dropdownMenu={
                            <DropdownMenu
                              setAnchorEl={setAnchorEl}
                              anchorEl={anchorEl}
                              setWidgetSize={setWidgetSize}
                            />
                          }
                        />
                      </Box>
                    </Grid>
                  )}
                  {/* FUTURE USE */}
                  {/* 
                  {filterService('Risks') && (
                    <Grid
                      key="dashboard-top-risks"
                      item
                      onClick={(e) =>
                        handleClickNavigate(e, `${PageRouteConstants.RiskManagement}`)
                      }>
                      <Box
                        sx={{
                          border: '1px solid #CCCCCC',
                          width: 1,
                          height: 1,
                          backgroundColor: '#FFFFFF',
                          borderRadius: '8px'
                        }}>
                        <TopRiskWidget
                          size={10}
                          code={'ASM'}
                          source=""
                          column={4}
                          title="Top Risks"
                          isDragable={isDragable}
                          currentGrid={currentGrid}
                          customIcon={<EditIcon className="grid-edit-icon" fontSize="small" />}
                          dropdownMenu={
                            <DropdownMenu
                              setAnchorEl={setAnchorEl}
                              anchorEl={anchorEl}
                              setWidgetSize={setWidgetSize}
                            />
                          }
                        />
                      </Box>
                    </Grid>
                  )} */}

                  {filterService('Risks') && (
                    <Grid
                      key="dashboard-risks-stats"
                      item
                      onClick={(e) =>
                        handleClickNavigate(e, `${PageRouteConstants.RiskManagement}`)
                      }>
                      <Box
                        sx={{
                          border: '1px solid #CCCCCC',
                          width: 1,
                          height: 1,
                          backgroundColor: '#FFFFFF',
                          borderRadius: '8px',
                          cursor: 'default'
                        }}>
                        <Box>
                          <Box
                            sx={{
                              px: 3,
                              pt: 3,
                              flexGrow: 1,
                              display: 'flex',
                              flexDirection: 'column'
                            }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                              <Box sx={{ mb: 2 }}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                  }}>
                                  <Typography sx={{ fontWeight: 600 }}>Open Risks</Typography>
                                </Box>
                              </Box>
                              {/* <Box>
                                {prop?.isDragable && (
                                  <>
                                    {prop?.customIcon}
                                    {prop?.currentGrid?.i === 'dashboard-top-risks' ? prop?.dropdownMenu : null}
                                  </>
                                )}
                              </Box> */}
                            </Box>

                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 2,
                                flexWrap: 'wrap',
                                mb: 2
                              }}>
                              <Box
                                sx={{
                                  background: '#FBFBFF',
                                  border: '1px solid #CCCCCC',
                                  borderRadius: 1,
                                  padding: 1.5,
                                  width: 'calc(50% - 8px)',
                                  height: '81px'
                                }}>
                                <Typography
                                  sx={{
                                    fontWeight: 600,
                                    fontSize: '19px',
                                    mb: 1,
                                    lineHeight: '23px'
                                  }}>
                                  Total
                                </Typography>
                                <Typography sx={{ fontWeight: 600, fontSize: '33px' }}>
                                  {riskStats?.total ?? 0}
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  background: '#FBFBFF',
                                  border: '1px solid #CCCCCC',
                                  borderRadius: 1,
                                  padding: 1.5,
                                  width: 'calc(50% - 8px)',
                                  height: '81px'
                                }}>
                                <Typography
                                  sx={{
                                    fontWeight: 600,
                                    fontSize: '19px',
                                    mb: 1,
                                    lineHeight: '23px'
                                  }}>
                                  Accepted
                                </Typography>
                                <Typography sx={{ fontWeight: 600, fontSize: '33px' }}>
                                  {riskStats?.accepted ?? 0}
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  background: '#FFFFFF',
                                  border: '1px solid #CCCCCC',
                                  borderRadius: 1,
                                  padding: 1.5,
                                  width: 'calc(50% - 8px)',
                                  height: '81px'
                                }}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    justifyContent: 'space-between'
                                  }}>
                                  <Box>
                                    <Typography
                                      sx={{
                                        fontWeight: 600,
                                        fontSize: '23px',
                                        lineHeight: '27px'
                                      }}>
                                      {riskStats?.critical ?? 0}
                                    </Typography>
                                    <Typography variant="caption" sx={{ fontWeight: 600 }}>
                                      Critical
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      width: '32px',
                                      height: '32px',
                                      background: '#FFE6E2',
                                      borderRadius: '50%',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center'
                                    }}>
                                    <FlagIcon sx={{ fontSize: '14px', color: '#F73939' }} />
                                  </Box>
                                </Box>
                              </Box>

                              <Box
                                sx={{
                                  background: '#FFFFFF',
                                  border: '1px solid #CCCCCC',
                                  borderRadius: 1,
                                  padding: 1.5,
                                  width: 'calc(50% - 8px)',
                                  height: '81px'
                                }}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    justifyContent: 'space-between'
                                  }}>
                                  <Box>
                                    <Typography
                                      sx={{
                                        fontWeight: 600,
                                        fontSize: '23px',
                                        lineHeight: '27px'
                                      }}>
                                      {riskStats?.high ?? 0}
                                    </Typography>
                                    <Typography variant="caption" sx={{ fontWeight: 600 }}>
                                      High
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      width: '32px',
                                      height: '32px',
                                      background: '#FDE68A',
                                      borderRadius: '50%',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center'
                                    }}>
                                    <FlagIcon sx={{ fontSize: '14px', color: '#F59E0B' }} />
                                  </Box>
                                </Box>
                              </Box>

                              <Box
                                sx={{
                                  background: '#FFFFFF',
                                  border: '1px solid #CCCCCC',
                                  borderRadius: 1,
                                  padding: 1.5,
                                  width: 'calc(50% - 8px)',
                                  height: '81px'
                                }}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    justifyContent: 'space-between'
                                  }}>
                                  <Box>
                                    <Typography
                                      sx={{
                                        fontWeight: 600,
                                        fontSize: '23px',
                                        lineHeight: '27px'
                                      }}>
                                      {riskStats?.medium ?? 0}
                                    </Typography>
                                    <Typography variant="caption" sx={{ fontWeight: 600 }}>
                                      Medium
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      width: '32px',
                                      height: '32px',
                                      background: '#FEF3C7',
                                      borderRadius: '50%',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center'
                                    }}>
                                    <FlagIcon sx={{ fontSize: '14px', color: '#FBBF24' }} />
                                  </Box>
                                </Box>
                              </Box>

                              <Box
                                sx={{
                                  background: '#FFFFFF',
                                  border: '1px solid #CCCCCC',
                                  borderRadius: 1,
                                  padding: 1.5,
                                  width: 'calc(50% - 8px)',
                                  height: '81px'
                                }}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    justifyContent: 'space-between'
                                  }}>
                                  <Box>
                                    <Typography
                                      sx={{
                                        fontWeight: 600,
                                        fontSize: '23px',
                                        lineHeight: '27px'
                                      }}>
                                      {riskStats?.low ?? 0}
                                    </Typography>
                                    <Typography variant="caption" sx={{ fontWeight: 600 }}>
                                      Low
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      width: '32px',
                                      height: '32px',
                                      background: '#D0DAFC',
                                      borderRadius: '50%',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center'
                                    }}>
                                    <FlagIcon sx={{ fontSize: '14px', color: '#436AF3' }} />
                                  </Box>
                                </Box>
                              </Box>
                            </Box>

                            <Box>
                              <Button
                                disabled={isDragable ? true : false}
                                className="redirect-btn"
                                onClick={(e) =>
                                  handleClickNavigateButton(
                                    e,
                                    `${PageRouteConstants.RiskManagement}?risk_expire=expire-next-30-days`
                                  )
                                }
                                sx={{ width: '100%', textTransform: 'none' }}
                                variant="outlined">
                                {(riskStats?.risks_expiring_in_30_days ?? 0) > 1 && (
                                  <p
                                    className="redirect-btn"
                                    style={{
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      margin: 0
                                    }}>
                                    {riskStats?.risks_expiring_in_30_days ?? 0} Risks Expiring in
                                    the Next 30 Days
                                  </p>
                                )}
                                {(riskStats?.risks_expiring_in_30_days ?? 0) <= 1 && (
                                  <p
                                    className="redirect-btn"
                                    style={{
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      margin: 0
                                    }}>
                                    {riskStats?.risks_expiring_in_30_days ?? 0} Risk Expiring in the
                                    Next 30 Days
                                  </p>
                                )}
                                {/* <p
                                  className="redirect-btn"
                                  style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    margin: 0
                                  }}>
                                  {riskStats?.risks_expiring_in_30_days ?? 0} Risks Expiring in the
                                  Next 30 Days
                                </p> */}
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  )}

                  {/* {filterService('Events') && (
                    <Grid
                      key="dashboard-top-events"
                      item
                      onClick={(e) => handleClickNavigate(e, `${PageRouteConstants.SIEM}`)}>
                      <Box
                        sx={{
                          border: '1px solid #CCCCCC',
                          width: 1,
                          height: 1,
                          backgroundColor: '#FFFFFF',
                          borderRadius: '8px'
                        }}>
                        <TopEventWidget
                          size={10}
                          code={''}
                          source=""
                          column={4}
                          title="Top Events"
                          isDragable={isDragable}
                          currentGrid={currentGrid}
                          customIcon={<EditIcon className="grid-edit-icon" fontSize="small" />}
                          dropdownMenu={
                            <DropdownMenu
                              setAnchorEl={setAnchorEl}
                              anchorEl={anchorEl}
                              setWidgetSize={setWidgetSize}
                            />
                          }
                        />
                      </Box>
                    </Grid>
                  )} */}

                  {filterService('Network Detection') && (
                    <Grid
                      key="dashboard-network-protection"
                      item
                      onClick={(e) => handleClickNavigate(e, PageRouteConstants?.NetwrokDetection)}>
                      <Box
                        sx={{
                          border: '1px solid #CCCCCC',
                          width: 1,
                          height: 1,
                          backgroundColor: '#FFFFFF',
                          borderRadius: '8px',
                          cursor: 'default'
                        }}>
                        <Box
                          sx={{
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1.5,
                            height: 1
                          }}>
                          <Box sx={{ mb: 4 }}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                mb: 1
                              }}>
                              <Typography sx={{ fontWeight: 600 }}>Network Detection</Typography>
                              {isDragable && (
                                <>
                                  <EditIcon className="grid-edit-icon" fontSize="small" />
                                  {currentGrid?.i === 'dashboard-network-protection' ? (
                                    <DropdownMenu
                                      setAnchorEl={setAnchorEl}
                                      anchorEl={anchorEl}
                                      setWidgetSize={setWidgetSize}
                                    />
                                  ) : null}
                                </>
                              )}
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                              <Typography variant="caption">
                                From {networkstats?.total ? networkstats?.total : 0} total findings
                              </Typography>
                            </Box>
                          </Box>
                          <Box sx={{ flexGrow: 1 }}>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1.5,

                                height: 1,
                                justifyContent: 'space-between'
                              }}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  border: '1px solid #EAECF0',
                                  borderRadius: '8px',
                                  p: '14px'
                                }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                  <Box
                                    sx={{
                                      width: '24px',
                                      height: '24px',
                                      backgroundColor: renderColorBackGround('critical'),
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      borderRadius: '50%'
                                    }}>
                                    <FlagIcon
                                      sx={{
                                        color: renderColor('critical'),
                                        width: '12px'
                                      }}
                                    />
                                  </Box>
                                  <Typography
                                    variant={'body1'}
                                    sx={{ fontWeight: 600, color: '#696969' }}>
                                    Critical
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    sx={{ fontWeight: 600, fontSize: '28px', lineHeight: '34px' }}>
                                    {networkstats?.critical ? networkstats?.critical : 0}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  border: '1px solid #EAECF0',
                                  borderRadius: '8px',
                                  p: '14px'
                                }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                  <Box
                                    sx={{
                                      width: '24px',
                                      height: '24px',
                                      backgroundColor: renderColorBackGround('high'),
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      borderRadius: '50%'
                                    }}>
                                    <FlagIcon
                                      sx={{
                                        color: renderColor('high'),
                                        width: '12px'
                                      }}
                                    />
                                  </Box>
                                  <Typography
                                    variant={'body1'}
                                    sx={{ fontWeight: 600, color: '#696969' }}>
                                    Suspicious
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    sx={{ fontWeight: 600, fontSize: '28px', lineHeight: '34px' }}>
                                    {networkstats?.suspicious ? networkstats?.suspicious : 0}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  border: '1px solid #EAECF0',
                                  borderRadius: '8px',
                                  p: '14px'
                                }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                  <Box
                                    sx={{
                                      width: '24px',
                                      height: '24px',
                                      backgroundColor: renderColorBackGround('medium'),
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      borderRadius: '50%'
                                    }}>
                                    <FlagIcon
                                      sx={{
                                        color: renderColor('medium'),
                                        width: '12px'
                                      }}
                                    />
                                  </Box>
                                  <Typography
                                    variant={'body1'}
                                    sx={{ fontWeight: 600, color: '#696969' }}>
                                    Compliance
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    sx={{ fontWeight: 600, fontSize: '28px', lineHeight: '34px' }}>
                                    {networkstats?.compliance ? networkstats?.compliance : 0}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  )}

                  {filterService('Tools') && (
                    <Grid
                      key="dashboard-endpoint-protection"
                      item
                      onClick={(e) =>
                        handleClickNavigate(e, PageRouteConstants?.EndpointProtection)
                      }>
                      <Box
                        sx={{
                          border: '1px solid #CCCCCC',
                          width: 1,
                          height: 1,
                          backgroundColor: '#FFFFFF',
                          borderRadius: '8px',
                          cursor: 'default'
                        }}>
                        <Box sx={{ p: 3, display: 'flex', flexDirection: 'column', height: 1 }}>
                          <Box sx={{ mb: 4 }}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                mb: 1
                              }}>
                              <Typography sx={{ fontWeight: 600 }}>Tools</Typography>
                              {isDragable && (
                                <>
                                  <EditIcon className="grid-edit-icon" fontSize="small" />
                                  {currentGrid?.i === 'dashboard-endpoint-protection' ? (
                                    <DropdownMenu
                                      setAnchorEl={setAnchorEl}
                                      anchorEl={anchorEl}
                                      setWidgetSize={setWidgetSize}
                                    />
                                  ) : null}
                                </>
                              )}
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                              <Typography variant="caption">
                                Status as of: {moment().format('DD MMM YYYY').toUpperCase()}
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              width: 1,
                              height: 1,
                              display: 'flex',
                              justifyContent: 'space-between',
                              flexDirection: 'column'
                            }}>
                            {parseInt(
                              format(
                                endpointProtectionStats?.data?.reduce(
                                  (a, b) => a + (Number.isInteger(b.count) ? b.count : 0),
                                  0
                                )
                              )
                            ) ? (
                              <ResponsiveContainer className="container" height={250} width="100%">
                                <PieChart>
                                  <text
                                    style={{ padding: '8px' }}
                                    fontWeight={700}
                                    x="50%"
                                    y="47%"
                                    textAnchor="middle"
                                    dominantBaseline="middle">
                                    {format(
                                      endpointProtectionStats?.data?.reduce(
                                        (a, b) => a + (Number.isInteger(b.count) ? b.count : 0),
                                        0
                                      )
                                    )}
                                  </text>
                                  <text
                                    x="50%"
                                    y="55%"
                                    textAnchor="middle"
                                    dominantBaseline="middle">
                                    Total
                                  </text>
                                  <Pie
                                    data={endpointProtectionRef.current}
                                    cx="50%"
                                    cy="50%"
                                    innerRadius={60}
                                    outerRadius={80}
                                    isAnimationActive={false}
                                    fill="#8884d8"
                                    paddingAngle={5}
                                    dataKey="value"
                                    label>
                                    {endpointProtectionRef.current.map((entry, index) => (
                                      <Cell key={`cell-${index}`} fill={entry.fill} />
                                    ))}
                                  </Pie>
                                </PieChart>
                              </ResponsiveContainer>
                            ) : (
                              <Box
                                sx={{
                                  height: 1,
                                  width: 1,
                                  textAlign: 'center',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center'
                                }}>
                                <Box
                                  sx={{
                                    maxWidth: '196px',
                                    height: '196px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: 1,
                                    borderRadius: '50%',
                                    border: '20px solid #EBEBEB',
                                    p: 2.5
                                  }}>
                                  <Typography variant="body2">
                                    0 <br /> Total
                                  </Typography>
                                </Box>
                              </Box>
                            )}
                            <Box
                              sx={{
                                display: 'flex',
                                gap: 1,
                                pt: 1,
                                flexWrap: 'wrap',
                                width: 1,
                                justifyContent: 'space-between'
                              }}>
                              {endpointProtectionRef.current.map((entry, index) => (
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                  <Box
                                    sx={{
                                      width: '14px',
                                      height: '14px',
                                      backgroundColor: entry.fill,
                                      borderRadius: '50%'
                                    }}></Box>
                                  <Typography variant="caption">{entry.name} </Typography>
                                </Box>
                              ))}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  )}

                  {filterService('Assets') && (
                    <Grid
                       key="dashboard-asset-management"
                      item
                      onClick={(e) => handleClickNavigate(e, PageRouteConstants?.AssetManagement)}>
                      <Box
                        sx={{
                          border: '1px solid #CCCCCC',
                          width: 1,
                          height: 1,
                          backgroundColor: '#FFFFFF',
                          borderRadius: '8px',
                          cursor: 'default'
                        }}>
                        <Box sx={{ p: 3, display: 'flex', flexDirection: 'column', height: 1 }}>
                          <Box sx={{ mb: 4 }}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                mb: 1
                              }}>
                              <Typography sx={{ fontWeight: 600 }}>Assets</Typography>
                              {isDragable && (
                                <>
                                  <EditIcon className="grid-edit-icon" fontSize="small" />
                                  {currentGrid?.i === 'dashboard-endpoint-protection' ? (
                                    <DropdownMenu
                                      setAnchorEl={setAnchorEl}
                                      anchorEl={anchorEl}
                                      setWidgetSize={setWidgetSize}
                                    />
                                  ) : null}
                                </>
                              )}
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                              <Typography variant="caption">
                                Status as of: {moment().format('DD MMM YYYY').toUpperCase()}
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              width: 1,
                              height: 1,
                              display: 'flex',
                              justifyContent: 'space-between',
                              flexDirection: 'column'
                            }}>
                            {parseInt(
                              format(
                                assetStats?.data?.status_counts?.reduce(
                                  (a, b) => a + (Number.isInteger(b.count) ? b.count : 0),
                                  0
                                )
                              )
                            ) ? (
                              <ResponsiveContainer className="container" height={250} width="100%">
                                <PieChart>
                                  <text
                                    style={{ padding: '8px' }}
                                    fontWeight={700}
                                    x="50%"
                                    y="47%"
                                    textAnchor="middle"
                                    dominantBaseline="middle">
                                    {format(
                                      assetStats?.data?.status_counts?.reduce(
                                        (a, b) => a + (Number.isInteger(b.count) ? b.count : 0),
                                        0
                                      )
                                    )}
                                  </text>
                                  <text
                                    x="50%"
                                    y="55%"
                                    textAnchor="middle"
                                    dominantBaseline="middle">
                                    Total
                                  </text>
                                  <Pie
                                    data={assetRef.current}
                                    cx="50%"
                                    cy="50%"
                                    innerRadius={60}
                                    outerRadius={80}
                                    isAnimationActive={false}
                                    fill="#8884d8"
                                    paddingAngle={5}
                                    dataKey="value"
                                    label>
                                    {assetRef.current.map((entry, index) => (
                                      <Cell key={`cell-${index}`} fill={entry.fill} />
                                    ))}
                                  </Pie>
                                </PieChart>
                              </ResponsiveContainer>
                            ) : (
                              <Box
                                sx={{
                                  height: 1,
                                  width: 1,
                                  textAlign: 'center',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center'
                                }}>
                                <Box
                                  sx={{
                                    maxWidth: '196px',
                                    height: '196px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: 1,
                                    borderRadius: '50%',
                                    border: '20px solid #EBEBEB',
                                    p: 2.5
                                  }}>
                                  <Typography variant="body2">
                                    0 <br /> Total
                                  </Typography>
                                </Box>
                              </Box>
                            )}
                            <Box
                              sx={{
                                display: 'flex',
                                gap: 1,
                                pt: 1,
                                flexWrap: 'wrap',
                                width: 1,
                                justifyContent: 'space-between'
                              }}>
                              {assetRef.current.map((entry, index) => (
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                  <Box
                                    sx={{
                                      width: '14px',
                                      height: '14px',
                                      backgroundColor: entry.fill,
                                      borderRadius: '50%'
                                    }}></Box>
                                  <Typography variant="caption">{entry.name} </Typography>
                                </Box>
                              ))}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  )}

                  {emailProtection &&
                    process.env.REACT_APP_SHOW_PARTIAL_FEATURES === 'true' &&
                    filterService('Email Protection') && (
                      <Grid
                        key="dashboard-email-protection"
                        item
                        onClick={(e) =>
                          handleClickNavigate(e, PageRouteConstants?.EmailProtection)
                        }>
                        <Box
                          sx={{
                            border: '1px solid #CCCCCC',
                            width: 1,
                            height: 1,
                            backgroundColor: '#FFFFFF',
                            borderRadius: '8px',
                            cursor: 'default'
                          }}>
                          <Box sx={{ p: 3 }}>
                            <Box sx={{ mb: 4 }}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  mb: 1
                                }}>
                                <Typography sx={{ fontWeight: 600 }}>
                                  Email Threat Overview
                                </Typography>
                                {isDragable && (
                                  <>
                                    <EditIcon className="grid-edit-icon" fontSize="small" />
                                    {currentGrid?.i === 'dashboard-email-protection' ? (
                                      <DropdownMenu
                                        setAnchorEl={setAnchorEl}
                                        anchorEl={anchorEl}
                                        setWidgetSize={setWidgetSize}
                                      />
                                    ) : null}
                                  </>
                                )}
                              </Box>
                              <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                <Typography variant="caption">Last 30 Days </Typography>
                              </Box>
                            </Box>
                            <Box
                              sx={{ mb: 1, width: 1, display: 'flex', justifyContent: 'center' }}>
                              <ResponsiveContainer className="container" height={250} width="100%">
                                <PieChart>
                                  <text
                                    fontWeight={700}
                                    x="50%"
                                    y="47%"
                                    textAnchor="middle"
                                    dominantBaseline="middle">
                                    {format(
                                      emailProtectionStats?.data?.reduce(
                                        (a, b) => a + (Number.isInteger(b.count) ? b.count : 0),
                                        0
                                      )
                                    )}
                                  </text>
                                  <text
                                    x="50%"
                                    y="55%"
                                    textAnchor="middle"
                                    dominantBaseline="middle">
                                    Total
                                  </text>
                                  <Pie
                                    data={emailProtectionRef.current}
                                    cx="50%"
                                    cy="50%"
                                    innerRadius={60}
                                    outerRadius={80}
                                    isAnimationActive={false}
                                    fill="#8884d8"
                                    paddingAngle={5}
                                    dataKey="value"
                                    label>
                                    {emailProtectionRef.current.map((entry, index) => (
                                      <Cell key={`cell-${index}`} fill={entry.fill} />
                                    ))}
                                  </Pie>
                                </PieChart>
                              </ResponsiveContainer>
                            </Box>
                            <Box
                              sx={{
                                display: 'flex',

                                width: 0.9,
                                m: 'auto'
                              }}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  gap: 2,
                                  pt: 1,
                                  width: 0.9,
                                  m: 'auto'
                                }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                  <Box
                                    sx={{
                                      width: '14px',
                                      height: '14px',
                                      backgroundColor: bgColorForEmailProtection('SPAM'),
                                      borderRadius: '50%'
                                    }}></Box>
                                  <Typography variant="caption">Spam</Typography>
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  gap: 2,
                                  pt: 1,
                                  width: 0.9,
                                  m: 'auto'
                                }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                  <Box
                                    sx={{
                                      width: '14px',
                                      height: '14px',
                                      backgroundColor: bgColorForEmailProtection('MALWARE'),
                                      borderRadius: '50%'
                                    }}></Box>
                                  <Typography variant="caption">Malware</Typography>
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  gap: 2,
                                  pt: 1,
                                  width: 0.9,
                                  m: 'auto'
                                }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                  <Box
                                    sx={{
                                      width: '14px',
                                      height: '14px',
                                      backgroundColor: bgColorForEmailProtection('FRAUD'),
                                      borderRadius: '50%'
                                    }}></Box>
                                  <Typography variant="caption">Fraud</Typography>
                                </Box>
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                width: 0.9,
                                gap: 4,
                                m: 'auto'
                              }}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  gap: 1,
                                  pt: 1
                                }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                  <Box
                                    sx={{
                                      width: '14px',
                                      height: '14px',
                                      backgroundColor: bgColorForEmailProtection('CLEAN'),
                                      borderRadius: '50%'
                                    }}></Box>
                                  <Typography variant="caption">Clean</Typography>
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  gap: 2,
                                  pt: 1
                                }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                  <Box
                                    sx={{
                                      width: '14px',
                                      height: '14px',
                                      backgroundColor: bgColorForEmailProtection('BLOCKED'),
                                      borderRadius: '50%'
                                    }}></Box>
                                  <Typography variant="caption">Blocked</Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    )}

                  {process.env.REACT_APP_SHOW_PARTIAL_FEATURES === 'true' && (
                    <Grid
                      key="dashboard-security-info-event-management"
                      onClick={(e) => handleClickNavigate(e, PageRouteConstants?.SIEM)}>
                      <Box
                        sx={{
                          border: '1px solid #CCCCCC',
                          width: 1,
                          height: 1,
                          backgroundColor: '#FFFFFF',
                          borderRadius: '8px',
                          cursor: 'default'
                        }}>
                        <Box sx={{ p: 3 }}>
                          <Box sx={{ mb: securityInfoManagement?.w === 1 ? 2 : 4 }}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                mb: 1
                              }}>
                              <Typography sx={{ fontWeight: 600 }}>Events</Typography>
                              {isDragable && (
                                <>
                                  <EditIcon className="grid-edit-icon" fontSize="small" />
                                  {currentGrid?.i === 'dashboard-security-info-event-management' ? (
                                    <DropdownMenu
                                      setAnchorEl={setAnchorEl}
                                      anchorEl={anchorEl}
                                      setWidgetSize={setWidgetSize}
                                    />
                                  ) : null}
                                </>
                              )}
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                              <Typography variant="caption">From 100,000 total findings</Typography>
                            </Box>
                          </Box>

                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: 1.5,
                              px: 2,
                              py: 4
                            }}>
                            <Grid
                              className={securityInfoManagement?.w === 1 ? 'siem-wrapper' : ''}
                              container
                              spacing={2}
                              rowGap={4}>
                              <Grid className="siem-info" xs={6} md={4}>
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                  <Box>
                                    <SettingIconDashboard />
                                  </Box>
                                  <Box>
                                    <Box>
                                      <Typography
                                        variant="h1"
                                        sx={{ color: 'primary.main', fontSize: '40px' }}>
                                        2
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        sx={{
                                          whiteSpace: 'nowrap',
                                          fontWeight: 600,
                                          color: '#696969'
                                        }}>
                                        Users Click to Setup
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid className="siem-info" xs={6} md={4}>
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                  <Box>
                                    <EventDashboard />
                                  </Box>
                                  <Box>
                                    <Box>
                                      <Typography
                                        variant="h1"
                                        sx={{ color: 'primary.main', fontSize: '40px' }}>
                                        3M
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        sx={{
                                          whiteSpace: 'nowrap',
                                          fontWeight: 600,
                                          color: '#696969'
                                        }}>
                                        Events Processed
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid className="siem-info" xs={6} md={4}>
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                  <Box>
                                    <NotableIcon />
                                  </Box>
                                  <Box>
                                    <Box>
                                      <Typography
                                        variant="h1"
                                        sx={{ color: 'primary.main', fontSize: '40px' }}>
                                        8
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        sx={{
                                          whiteSpace: 'nowrap',
                                          fontWeight: 600,
                                          color: '#696969'
                                        }}>
                                        Notable Behavior
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid className="siem-info" xs={6} md={4}>
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                  <Box>
                                    <NewAlert />
                                  </Box>
                                  <Box>
                                    <Box>
                                      <Typography
                                        variant="h1"
                                        sx={{ color: 'primary.main', fontSize: '40px' }}>
                                        0
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        sx={{
                                          whiteSpace: 'nowrap',
                                          fontWeight: 600,
                                          color: '#696969'
                                        }}>
                                        New Alerts
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid className="siem-info" xs={6} md={4}>
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                  <Box>
                                    <EndpointDashboard />
                                  </Box>
                                  <Box>
                                    <Box>
                                      <Typography
                                        variant="h1"
                                        sx={{ color: 'primary.main', fontSize: '40px' }}>
                                        90
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        sx={{
                                          whiteSpace: 'nowrap',
                                          fontWeight: 600,
                                          color: '#696969'
                                        }}>
                                        Endpoint Agents
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid className="siem-info" xs={6} md={4}>
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                  <Box>
                                    <FolderIcon />
                                  </Box>
                                  <Box>
                                    <Box>
                                      <Typography
                                        variant="h1"
                                        sx={{ color: 'primary.main', fontSize: '40px' }}>
                                        1
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        sx={{
                                          whiteSpace: 'nowrap',
                                          fontWeight: 600,
                                          color: '#696969'
                                        }}>
                                        Data Collection Issue
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  )}
                </ResponsiveGridLayout>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};
