import { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  IconButton
} from '@mui/material';
import WarningIcon from '@jibin/common/icons/Warning/Warning';
import CloseIcon from '@jibin/common/icons/Close/Close';
import { LoadingButton } from '@mui/lab';
import { CompanySettingsService } from '../CompanySettings.Api';

const DeleteDialog = ({ openDelete, handleCloseDelete, commonDetails, GetUserDetails }) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleConfirmDelete = async () => {
    try {
      setIsLoading(true);
      const res = await CompanySettingsService.RemoveCompanyPhoto(
        commonDetails?.data?.company?.company_uuid,
        ''
      );
      handleCloseDelete();
      GetUserDetails();
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            px: { xs: 1, md: 2 },
            py: { xs: 0.5, md: 1 },
            bgcolor: 'error.red100',
            mb: { xs: 2, md: 3 }
          }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: 'error.main',
                fontWeight: '600',
                maxWidth: 'calc(100% - 40px)',
                p: 1
              }}>
              <WarningIcon sx={{ mr: 1 }} />
              Confirm Action
            </Typography>
            <IconButton
              sx={{ color: 'text.primary' }}
              aria-label="close"
              onClick={handleCloseDelete}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ p: { xs: 2, md: 3 } }}>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ color: 'text.primary', fontWeight: 700, mb: 1 }}>
            Are you sure you want to delete the company image?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ px: { xs: 2, md: 3 }, pb: { xs: 1, md: 2 }, pt: 0 }}>
          <Button variant="outlined" sx={{ minWidth: 100 }} onClick={handleCloseDelete}>
            Cancel
          </Button>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            sx={{ minWidth: 100 }}
            color="error"
            onClick={() => handleConfirmDelete()}>
            {' '}
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteDialog;
