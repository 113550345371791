import { useState } from 'react';
import { Button, Checkbox, Menu, MenuItem, Tooltip } from '@mui/material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';

const FilterDropdown = (props) => {
  const {
    setRiskFilterform,
    riskFilterform,
    servicesOption,
    remediationStatusItems,
    SeverityList,
    handleChange
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null);
  const [subMenuAnchorElManufacture, setSubMenuAnchorElManufacture] = useState(null);
  const [subMenuAnchorElCriticality, setSubMenuAnchorElCriticality] = useState(null);

  const handleMainMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSubMenuClick = (event) => {
    setSubMenuAnchorEl(event.currentTarget);
  };
  const handleSubMenuClickManufacture = (event) => {
    setSubMenuAnchorElManufacture(event.currentTarget);
  };
  const handleSubMenuClickCriticality = (event) => {
    setSubMenuAnchorElCriticality(event.currentTarget);
  };

  const handleClose = () => {
    setSubMenuAnchorEl(null);
    setSubMenuAnchorElManufacture(null);
    setSubMenuAnchorElCriticality(null);
  };

  const handleCloseMain = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (type, item) => {
    setRiskFilterform((prevState) => {
      let updatedArray;
      updatedArray = prevState[type].split(',');
      const itemIndex = updatedArray.indexOf(item);
      if (itemIndex !== -1) {
        updatedArray.splice(itemIndex, 1);
      } else {
        updatedArray.push(item);
      }

      handleChange({ target: { value: updatedArray, name: type } });
      return {
        ...prevState,
        [type]: updatedArray
      };
    });
  };

  return (
    <div className="multi-dropdown">
      <Button aria-controls="main-menu" aria-haspopup="true" onClick={handleMainMenuClick}>
        <FilterAltOutlinedIcon /> +
      </Button>
      <Menu id="main-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMain}>
        <MenuItem sx={{ fontWeight: 600 }} onClick={handleSubMenuClickCriticality}>
          <KeyboardArrowRightOutlinedIcon sx={{ width: '16px' }} />
          Criticality
        </MenuItem>
        <MenuItem sx={{ fontWeight: 600 }} onClick={handleSubMenuClick}>
          <KeyboardArrowRightOutlinedIcon sx={{ width: '16px' }} />
          Domains
        </MenuItem>
        <MenuItem sx={{ fontWeight: 600 }} onClick={handleSubMenuClickManufacture}>
          <KeyboardArrowRightOutlinedIcon sx={{ width: '16px' }} />
          Statuses
        </MenuItem>
      </Menu>

      <Menu
        anchorEl={subMenuAnchorElCriticality}
        open={Boolean(subMenuAnchorElCriticality)}
        onClose={handleClose}
        sx={{ height: '350px' }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}>
        {/* <Typography
          sx={{ display: 'flex', alignItems: 'center', fontWeight: 600, padding: '8px' }}
          variant="body1">
          <KeyboardArrowRightOutlinedIcon sx={{ width: '16px' }} />
          Services
        </Typography> */}
        {SeverityList?.map((el: any, index) => (
          <MenuItem
            key={index}
            onClick={() => handleMenuItemClick('criticalities', el?.value)}
            sx={{ fontSize: '14px' }}
            value={el?.value}
            autoFocus={index === 0}>
            <Checkbox checked={riskFilterform?.criticalities?.indexOf(el?.value) > -1} /> {el?.key}
          </MenuItem>
        ))}
      </Menu>

      <Menu
        anchorEl={subMenuAnchorEl}
        open={Boolean(subMenuAnchorEl)}
        onClose={handleClose}
        sx={{ height: '350px' }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}>
        {/* <Typography
          sx={{ display: 'flex', alignItems: 'center', fontWeight: 600, padding: '8px' }}
          variant="body1">
          <KeyboardArrowRightOutlinedIcon sx={{ width: '16px' }} />
          Services
        </Typography> */}
        {servicesOption?.map((el: any, index) => (
          <Tooltip arrow placement="top" title={el.tooltip}>
            <MenuItem
              onClick={() => handleMenuItemClick('services_uuids', el?.value)}
              sx={{ fontSize: '14px' }}
              value={el?.value}
              autoFocus={index === 0}>
              <Checkbox checked={riskFilterform?.services_uuids?.indexOf(el?.value) > -1} />{' '}
              {el?.key}
            </MenuItem>
          </Tooltip>
        ))}
      </Menu>

      <Menu
        anchorEl={subMenuAnchorElManufacture}
        open={Boolean(subMenuAnchorElManufacture)}
        onClose={handleClose}
        sx={{ height: '350px' }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}>
        {/* <Typography
          sx={{ display: 'flex', alignItems: 'center', fontWeight: 600, padding: '8px' }}
          variant="body1">
          <KeyboardArrowRightOutlinedIcon sx={{ width: '16px' }} />
          Statuses
        </Typography> */}
        {remediationStatusItems?.map((el: any, index) => (
          <MenuItem
            onClick={() => handleMenuItemClick('statuses', el?.value)}
            sx={{ fontSize: '14px' }}
            value={el?.value}
            autoFocus={index === 0}>
            <Checkbox checked={riskFilterform?.statuses?.indexOf(el?.value) > -1} /> {el?.key}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default FilterDropdown;
