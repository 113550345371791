import Style from '@jibin/common/style/Styles';
import {
  Autocomplete,
  Avatar,
  Badge,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { FC, useCallback, useEffect, useState } from 'react';
import GoldIcon from '@jibin/common/icons/MedalIcon/GoldIcon.svg';
import SilverIcon from '@jibin/common/icons/MedalIcon/SilverIcon.svg';
import BronzeIcon from '@jibin/common/icons/MedalIcon/BronzeIcon.svg';
import PlatniumIcon from '@jibin/common/icons/MedalIcon/PlatniumIcon.svg';
import HelpIcon from '@jibin/common/icons/HelpIcon/HelpIcon';
import CheckBoxLight from '@jibin/common/icons/CheckBoxLight/CheckBoxLight';
import CheckBoxLightBlue from '@jibin/common/icons/CheckBoxLightBlue/CheckBoxLightBlue';
import CheckBoxOutLine from '@jibin/common/icons/CheckBoxOutLine/CheckBoxOutLine';

import { useLocation, useNavigate } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { useFormik } from 'formik';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import { LoadingButton } from '@mui/lab';
import { EllipsisContent } from '@jibin/common/components/EllipsisContent';
import SearchIcon from '@jibin/common/icons/Search/Search';
import { CompanyManagementApi } from 'src/Pages/CompanyManagement/CompanyManagement.Api';
import CheckCircleIcon from '@jibin/common/icons/CheckCircle/CheckCircle';
import moment from 'moment';
import { CompanySettingsService } from '../CompanySettings.Api';
import { MyAccountApi } from 'src/Pages/MyAccount/MyAccount.Api';
import { Delete, PhotoCamera } from '@mui/icons-material';
import DeleteDialog from './DeleteDialog';
import commonDetailSlice from 'src/store/reducers/commonReducer';
const CompanyInformation: FC = () => {
  const loadMedal = (type) => {
    switch (type) {
      case 'gold':
      case 'Gold':
        return <img width={'16px'} src={GoldIcon} />;

      case 'silver':
      case 'Silver':
        return <img width={'16px'} src={SilverIcon} />;
      case 'bronze':
      case 'Bronze':
        return <img width={'16px'} src={BronzeIcon} />;
      case 'platinum':
      case 'Platinum':
        return <img width={'16px'} src={PlatniumIcon} />;
    }
  };
  const Framework = [
    { name: 'CIS', id: 1 },
    { name: 'MPA', id: 2 }
  ];
  const Service = [
    {
      name: 'Policies',

      tooltip: 'Save time and gain agility by selecting the templates you need'
    },
    {
      name: 'Security Awareness',

      tooltip: 'Employees are the first line of defense against cyber threats'
    },
    {
      name: 'Vulnerability Management',

      tooltip: 'Proactive scanning to detect & remediate cybersecurity vulnerabilities'
    },
    {
      name: 'Gap Analysis',

      tooltip: 'Assessment of your cybersecurity program against one or many leading frameworks'
    },
    {
      name: 'Compliance',

      tooltip: 'Assessment of your cybersecurity program against one or many leading frameworks'
    },
    {
      name: 'Penetration Testing',

      tooltip: 'Simulated cyberattack to proactively test for vulnerabilities'
    },
    {
      name: 'Attack Surface Management',

      tooltip: 'Enhancing security posture by minimizing attack surface'
    },
    {
      name: 'Network Detection',

      tooltip: 'Ensuring proactive security against potential risks and breaches'
    },
    {
      name: 'Endpoint Detection',

      tooltip: 'Manage sensors and monitor your organization´s endpoints'
    },
    {
      name: 'Email Protection',

      tooltip: 'Advanced email security to protect against phishing attacks'
    },
    {
      name: 'Remediation Tracker',

      tooltip: 'Prioritized list of cybersecurity remediations'
    },
    {
      name: 'Events',

      tooltip: 'Detect, analyze and respond to cyber threats in real time'
    }
  ];

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [values, setValue] = useState<any>({ Framework: [], Service: [] });
  const [country, setCountry] = useState('US');
  const [address, setAddress] = useState({ address1: '', address2: '' });
  const [adressError, setAdressError] = useState({ address1: false, address2: false });
  const [companyData, setCompanyData] = useState<any>({});
  const [autocompleteInputRef, setautocompleteInputRef] = useState({} as any);
  const [onBoardingSteps, setOnBoardingStep] = useState([]);
  const [settings, setSettings] = useState({} as any);
  const [setState, setSetState] = useState('');
  const [isRefresh, setIsRefresh] = useState(false);
  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false);
  const [isOnBoardButtonLoading, setIsOnBoardButtonLoading] = useState(false);
  const [listOfServices, setListOfServices] = useState([]);
  const [listOfFramework, setListOfFramework] = useState([]);
  const location = useLocation();
  const [openDelete, setOpenDelete] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [filterData, setFilterData] = useState({
    Service: [''],
    status: ['not_started', 'in_progress', 'reopened'],
    Framework: [''],
    tag_uuids: ['']
  });
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };
  const renderValue = (selected: any, options: any) => {
    const selectedLabels = selected.map((value) => {
      const selectedOption = options.find((option) => option?.value === value);

      return selectedOption ? selectedOption?.key : '';
    });

    return selectedLabels.join(', ');
  };
  const ITEM_HEIGHT = 50;
  const ITEM_PADDING_TOP = 10;
  const MenuProps = {
    PaperProps: {
      style: {
        fontSize: '14px',
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  };
  const handleChange = async (event: any) => {
    // let {
    //   target: { value, name }
    // } = event;
    const file = {
      file_name: event.target.files[0].name,
      file_size: event.target.files[0].size,
      file_type: event.target.files[0].type
    };
    try {
      const res = await CompanySettingsService.PutCompanyPhoto(
        commonDetails.data.company.company_uuid,
        { file }
      );
      await CompanySettingsService.UploadCompantPhoto(res, event.target.files[0]);
      GetUserDetails();
    } catch (error) { }
    // const filterValue = value.filter((el: any) => el != '');

    // let string;

    // if (filterValue.length == 0) {
    //   string = '';

    //   const item = { ...filterData };
    //   item[name] = typeof value === 'string' ? filterValue.split(',') : [''];

    //   setFilterData(item);
    // } else {
    //   string = filterValue.join(',');

    //   const item = { ...filterData };

    //   item[name] = typeof value === 'string' ? filterValue.split(',') : filterValue;

    //   setFilterData(item);
    // }
  };
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const formik = useFormik({
    initialValues: { frameworks: [], services: [], settings: {} },
    onSubmit: () => { }
  });
  const nevigate = useNavigate();
  const handleSubmitPOST = async () => {
    try {
      setIsOnBoardButtonLoading(true);
      const data = { ...companyData };
      data.frameworks = values.Framework;
      data.services = values.Service;

      data.settings = { ...data?.settings, onboarding: onBoardingSteps };
      delete data.plan;
      delete data.company_uuid;
      delete data.external_id;

      delete data.status;
      const res = await CompanyManagementApi.POSTCompanyData(
        commonDetails.data.company.company_uuid,
        commonDetails.data.user_uuid,
        location.search.split('=').pop(),
        data
      );
      setIsRefresh(!isRefresh);
    } catch (error) {
    } finally {
      setTimeout(() => {
        setIsOnBoardButtonLoading(false);
      }, 1000);
    }
  };

  const handleSubmitPATCH = async () => {
    try {
      setIsSubmitButtonLoading(true);
      const data = { ...companyData };
      data.frameworks = values.Framework;
      data.services = values.Service;

      data.settings = { ...data?.settings, onboarding: onBoardingSteps };
      delete data.plan;
      delete data.company_uuid;
      delete data.external_id;
      delete data.status;

      const res = await CompanyManagementApi.UpadteCompanyData(
        companyData.company_uuid,
        commonDetails.data.user_uuid,
        location.search.split('=').pop(),
        data
      );
      setIsRefresh(!isRefresh);
    } catch (error) {
    } finally {
      setTimeout(() => {
        setIsSubmitButtonLoading(false);
      }, 1000);
    }
  };
  const GetCompanyDetails = useCallback(async () => {
    try {
      const res = await CompanyManagementApi.GetComapnyDetails(
        commonDetails.data.company.company_uuid,
        commonDetails.data.user_uuid,
        commonDetails.data.company.external_id
      );

      const res2 = await CompanyManagementApi.GetCompanyFrameworks(
        commonDetails.data.company.company_uuid,
        commonDetails.data.user_uuid
      );

      const allFrameworks = [];

      res2?.data?.data?.forEach((element) => {
        const enabled = res?.data?.data?.frameworks?.find((el) => el.name === element.name);
        if (enabled?.name) {
          allFrameworks.push({
            name: element.name,
            enabled: true
          });
        } else {
          allFrameworks.push({
            name: element.name,
            enabled: false
          });
        }
      });

      setCompanyData(res?.data?.data);
      setOnBoardingStep(res?.data?.data?.settings?.onboarding);
      const services =
        res?.data?.data?.status?.toUpperCase() == 'ACTIVE' ||
          res?.data?.data?.status?.toUpperCase() == 'PENDING'
          ? res?.data?.data?.services || []
          : Service;
      const enabledServiceData = [];

      const servicesKeyValue = Service?.filter((obj1) =>
        services
          ?.filter((el: any) => el.name == obj1.name && el.enabled)
          .some((obj2) => obj2.name === obj1.name && obj2.enabled)
      );
      const enabledFrameworkData = [];
      const frameworksKeyValue: any = allFrameworks?.map((el: any) => {
        if (el?.enabled) {
          enabledFrameworkData.push(el.name);
        }
        return {
          key: el?.name,
          value: el?.name,
          enabled: el?.enabled,
          name: el?.name
        };
      });

      setFilterData({
        ...filterData,
        Service: enabledServiceData,
        Framework: enabledFrameworkData
      });
      setListOfFramework(frameworksKeyValue);
      setListOfServices(servicesKeyValue);

      setValue({
        ...values,
        Framework: allFrameworks,
        Service: services
      });
    } catch (error) {
      console.log('error ::', error);
    }
  }, [commonDetails]);

  const handleChangeCheckBox = (e, index) => {
    const item = onBoardingSteps;

    const findIndex = onBoardingSteps.findIndex((el) => el.position == index);
    item[findIndex].enabled = e.target.checked;

    setOnBoardingStep(item);
  };

  const handleChangeServiceCheckBox = (e, element, name) => {
    const item = values[name];

    const findIndex = values[name].findIndex((el) => el.name == element.name);

    item[findIndex].enabled = e.target.checked;

    setValue({
      ...values,
      [name]: item
    });
  };

  const GetUserDetails = async () => {
    MyAccountApi.GetUserData().then(
      async (q: any) => {
        dispatch(commonDetailSlice.actions.removecommonDetails());

        dispatch(commonDetailSlice.actions.setcommonDetails(q));
      },
      (err: any) => { }
    );
  };

  useEffect(() => {
    GetCompanyDetails();
  }, [GetCompanyDetails]);

  return (
    <>
      <Box sx={{ p: 4 }}>
        <Box
          sx={{
            ...Style.CompanyManagement.CommonBoxes
          }}>
          <Box>
            <Box sx={{ mb: 2 }}>
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                badgeContent={
                  isHovered && (
                    <Delete
                      onClick={() => setOpenDelete(true)}
                      sx={{ color: '#787878', cursor: 'pointer' }}
                    />
                  )
                }
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <IconButton
                  sx={{ width: '64px', height: '64px', backgroundColor: '#D9D9D9', p: 0 }}
                  color="primary"
                  aria-label="upload picture"
                  component="label">
                  <input hidden onChange={(e) => handleChange(e)} accept="image/*" type="file" />
                  {commonDetails.data.company.logo_url ? (
                    <img
                      width={'100%'}
                      height="100%"
                      style={{ objectFit: 'cover', borderRadius: '50%' }}
                      src={`${commonDetails.data.company.logo_url}`}
                      alt=""
                    />
                  ) : (
                    <PhotoCamera sx={{ color: 'grey' }} />
                  )}
                </IconButton>
              </Badge>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ width: 0.5 }}>
              <TableRow sx={{ p: 0 }}>
                <TableCell sx={{ py: 1, px: 0, border: 'none' }}>
                  {' '}
                  <Typography variant="body1" sx={{ fontWeight: 600, minWidth: '90px' }}>
                    Company Name:
                  </Typography>
                </TableCell>
                <TableCell sx={{ py: 1, px: 0, pl: 7, border: 'none' }}>
                  <Typography variant="body1" sx={{ color: '#696969' }}>
                    {companyData?.name}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ py: 1, px: 0, border: 'none' }}>
                  {' '}
                  <Typography variant="body1" sx={{ fontWeight: 600, minWidth: '90px' }}>
                    Website:
                  </Typography>
                </TableCell>
                <TableCell sx={{ py: 1, px: 0, pl: 7, border: 'none' }}>
                  <Typography variant="body1" sx={{ color: '#696969' }}>
                    {companyData?.website}
                  </Typography>
                </TableCell>
              </TableRow>
              {process.env.REACT_APP_SHOW_PARTIAL_FEATURES == 'true' && (
                <TableRow>
                  <TableCell sx={{ py: 1, px: 0, border: 'none' }}>
                    {' '}
                    <Typography variant="body1" sx={{ fontWeight: 600, minWidth: '90px' }}>
                      Contract Number:
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ py: 1, px: 0, pl: 7, border: 'none' }}>
                    <Typography variant="body1" sx={{ color: '#696969' }}>
                      094875
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
              {process.env.REACT_APP_SHOW_PARTIAL_FEATURES == 'true' && (
                <TableRow>
                  <TableCell sx={{ py: 1, px: 0, border: 'none' }}>
                    {' '}
                    <Typography variant="body1" sx={{ fontWeight: 600, minWidth: '90px' }}>
                      Company Admin:
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ py: 1, px: 0, pl: 7, border: 'none' }}>
                    <Typography variant="body1" sx={{ color: '#696969' }}>
                      Name Surname
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </Box>
            <Box sx={{ width: 0.5 }}>
              <TableRow sx={{ p: 0 }}>
                <TableCell sx={{ py: 1, px: 0, border: 'none' }}>
                  {' '}
                  <Typography variant="body1" sx={{ fontWeight: 600, minWidth: '90px' }}>
                    Sales Contact
                  </Typography>
                </TableCell>
                <TableCell sx={{ py: 1, px: 0, pl: 7, border: 'none' }}>
                  <Typography variant="body1" sx={{ color: '#696969' }}>
                    {companyData?.name}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ py: 1, px: 0, border: 'none' }}>
                  {' '}
                  <Typography variant="body1" sx={{ fontWeight: 600, minWidth: '90px' }}>
                    Contract Period
                  </Typography>
                </TableCell>
                <TableCell sx={{ py: 1, px: 0, pl: 7, border: 'none' }}>
                  <Typography variant="body1" sx={{ color: '#696969' }}>
                    {moment(companyData?.plan?.start_date).format('DD MMM YYYY')} to{' '}
                    {moment(companyData?.plan?.end_date).format('DD MMM YYYY')}
                  </Typography>
                </TableCell>
              </TableRow>
              {process.env.REACT_APP_SHOW_PARTIAL_FEATURES == 'true' && (
                <TableRow>
                  <TableCell sx={{ py: 1, px: 0, border: 'none' }}>
                    {' '}
                    <Typography variant="body1" sx={{ fontWeight: 600, minWidth: '90px' }}>
                      Next Billing Date
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ py: 1, px: 0, pl: 7, border: 'none' }}>
                    <Typography variant="body1" sx={{ color: '#696969' }}>
                      01 JAN 2024
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell sx={{ py: 1, px: 0, border: 'none' }}>
                  {' '}
                  <Typography
                    variant="body1"
                    sx={{ display: 'flex', alignItems: 'center', gap: 0.5, fontWeight: 600 }}>
                    Subscription
                  </Typography>
                </TableCell>
                <TableCell sx={{ py: 1, px: 0, pl: 7, border: 'none' }}>
                  {companyData?.plan?.status == 'active' && (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                      <Typography
                        variant="body1"
                        sx={{ display: 'flex', alignItems: 'center', gap: 0.5, fontWeight: 600 }}>
                        {loadMedal(companyData?.plan?.plan_type)} {companyData?.plan?.plan_type}
                      </Typography>
                      <Box
                        sx={{
                          px: 1,
                          py: '2px',
                          background: 'rgba(16, 185, 129, 0.1)',
                          borderRadius: '100px'
                        }}>
                        <Typography variant="caption" sx={{ fontWeight: 500, color: '#27AE60' }}>
                          Active
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  {companyData?.plan?.status == 'expired' && (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                      <Typography
                        variant="body1"
                        sx={{ display: 'flex', alignItems: 'center', gap: 0.5, fontWeight: 600 }}>
                        {loadMedal(companyData?.plan?.plan_type)} {companyData?.plan?.plan_type}
                      </Typography>
                      <Box
                        sx={{
                          px: 1,

                          py: '2px',
                          background: 'rgba(239, 68, 68, 0.1)',
                          borderRadius: '100px'
                        }}>
                        <Typography variant="caption" sx={{ fontWeight: 500, color: '#EF4444' }}>
                          Expired
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </TableCell>
              </TableRow>
            </Box>
          </Box>
        </Box>

        <>
          <Box sx={{ py: 1.5 }}>
            <Typography variant="subtitle2" sx={{ fontWeight: 600, color: '#696969' }}>
              Services included in your Company Subscription:
            </Typography>
          </Box>

          <Box sx={Style.CompanyManagement.CommonBoxes}>
            <FormGroup>
              <Grid container spacing={1}>
                {listOfServices
                  .sort?.((a: any, b: any) => (a.name > b.name ? 1 : -1))
                  ?.map((el: any, index) => (
                    <Grid key={index + 1} item xs={12} sm={6} lg={4}>
                      <FormControlLabel
                        sx={{ '.MuiFormControlLabel-label': { fontSize: '14px' } }}
                        control={<CheckCircleIcon sx={{ color: 'primary.main' }} />}
                        label={
                          <Box
                            sx={{
                              display: 'flex',
                              gap: 0.2,
                              ml: 1,
                              alignItems: 'center'
                            }}>
                            <Typography variant="body2" sx={{ fontWeight: 600, color: '#464646' }}>
                              {el.name}
                            </Typography>

                            <Tooltip title={el.tooltip} placement="top">
                              <IconButton>
                                <HelpIcon
                                  sx={{
                                    path: {
                                      stroke: '#9D9D9D'
                                    },
                                    fill: 'none',
                                    fontSize: '16px'
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        }
                      />
                    </Grid>
                  ))}
              </Grid>
            </FormGroup>
          </Box>
        </>
        {process.env.REACT_APP_SHOW_PARTIAL_FEATURES == 'true' && (
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
            <Button variant="text" sx={{ bgcolor: '#D9EDFF' }}>
              See All Subscription Plans
            </Button>
          </Box>
        )}
        <>
          <Box sx={{ py: 1.5 }}>
            <Typography variant="subtitle2" sx={{ fontWeight: 600, color: '#696969' }}>
              Purchased Frameworks
            </Typography>
          </Box>

          <Box sx={Style.CompanyManagement.CommonBoxes}>
            <FormGroup>
              <Grid container spacing={1}>
                {values.Framework?.filter((el: any) =>
                  filterData.Framework?.filter((element: any) => element == el?.name)?.some(
                    (obj2: any) => obj2 === el?.name
                  )
                )?.map((el, index) => (
                  <Grid key={index + 1} item xs={12} sm={6} lg={4}>
                    <FormControlLabel
                      sx={{ '.MuiFormControlLabel-label': { fontSize: '14px' } }}
                      control={<CheckCircleIcon sx={{ color: 'primary.main' }} />}
                      label={
                        <Typography variant="body2" sx={{ fontWeight: 600, ml: 1 }}>
                          {el.name}
                        </Typography>
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </FormGroup>
          </Box>
        </>
      </Box>
      <DeleteDialog
        openDelete={openDelete}
        handleCloseDelete={handleCloseDelete}
        commonDetails={commonDetails}
        GetUserDetails={GetUserDetails}
      />
    </>
  );
};

export default CompanyInformation;
