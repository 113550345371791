export const NotificationSourceTypeConstants = {
  GapAnalysis: 'Gap Analysis',
  Remediation: 'Remediation',
  RemediationTracker: 'Remediation Tracker',

  Vulnerability: 'Vulnerability Management (Rapid7)',
  Penetration: 'Penetration Test',
  Endpoint: 'Endpoint Detection',
  CustomerSuccess: 'Customer Success Services Review',
  MyAccount: 'My Account',
  CompanyInformation: 'Company Account',
  ManageUsers: 'Manage Users',
  Meetings: 'Meetings',
  Locations: 'Company Account - Locations page',
  Authorizations: 'Authorizations',
  PlanandBilling: 'Plan and Billing',
  RiskManagement: 'Risk Management',
  TechnicalInformation: 'Technical Information',
  TechnicalInformationApplications: 'Technical Information - Applications page',
  TechnicalInformationIPRanges: 'Technical Information - IP Ranges page',
  TechnicalInformationWireless: 'Technical Information - Wireless Networks page'
  //Technical Information - Applications page
  //Technical Information - Applications page
};
